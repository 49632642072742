// stores/auth.js
import { defineStore } from 'pinia'
import apiClient from '@/api/axios'
import { jwtDecode } from 'jwt-decode'

export const useAuthStore = defineStore('auth', {
  state: () => ({
    token: null,
    user: null,
    userData: null,
    loginTime: null,
    settings: {} // 添加 settings 状态
  }),
  actions: {
    async login(credentials) {
      try {
        const response = await apiClient.post('/auth/login', credentials)
        if (response.data.code == 200) {
          this.setToken(response.data.data.token, credentials.username)
          localStorage.setItem('loginTime', Date.now().toString())
          return response.data
        } else {
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.detail : error.message)
      }
    },
    async hospitallist() {
      //获取医院列表
      try {
        const response = await apiClient.get('/hospital/list')
        if (response.data.code === 200) {
          return response.data
        } else {
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.detail : error.message)
      }
    },
    async devicelist() {
      //设备列表
      try {
        const response = await apiClient.get('/device/list')
        if (response.data.code === 200) {
          return response.data
        } else {
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.detail : error.message)
      }
    },

    async getDepartmentList(code) {
      //获取部门列表
      try {
        const response = await apiClient.get(`/hospital/${code}/department`)
        if (response.data.code === 200) {
          //console.log(response.data.msg);
          return response.data
        } else {
          //console.log(response.data.msg);
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    async getSpecialParameters(deviceId, paramId) {
      //查询设备参数的特殊范围设置列表
      try {
        const response = await apiClient.get(`/device/${deviceId}/${paramId}/special`)
        if (response.data.code === 200) {
          return response.data
        } else {
          throw new Error(response.data.msg)
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.msg : error.message)
      }
    },
    async saveSpecialParameters(deviceId, paramId, params) {
      //添加特殊参数
      try {
        const response = await apiClient.post(`/device/${deviceId}/${paramId}/special`, params)
        if (response.data.code === 200) {
          return response.data
        } else {
          throw new Error(response.data.msg)
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    async deleteSpecialParameter(id) {
      try {
        const response = await apiClient.delete(`/device/parameter/special/${id}`)
        if (response.data.code === 200) {
          return response.data
        } else {
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    //http://192.168.110.250:8281/register/patient/list?t=1725514101141&pageNumber=1&pageSize=50
    //查询患者列表
    async getPatients(params) {
      try {
        const response = await apiClient.get(`/register/patient/list`, { params })
        if (response.data.code === 200) {
          //console.log("/register/patient/list>" + response.data.msg);
          return response.data
        } else {
          //console.log(response.data.msg);
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    // http://leidu.jiaoyidazhuo.com/FileSystem/Menu
    //查询文档节点
    async getDocumentNode() {
      try {
        const response = await apiClient.get(`/FileSystem/Menu`, {})
        if (response.data.code === 200) {
          return response.data
        } else {
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    // http://leidu.jiaoyidazhuo.com/FileSystem/SearchByID
    //获取文档列表
    async getDocumentList(params) {
      try {
        const response = await apiClient.get(`/FileSystem/SearchByID`, { params })
        if (response.data.code === 200) {
          return response.data
        } else {
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    // http://leidu.jiaoyidazhuo.com/FileSystem/SearchByName
    //获取文档列表
    async searchDocument(params) {
      try {
        const response = await apiClient.get(`/FileSystem/SearchByName`, { params })
        if (response.data.code === 200) {
          return response.data
        } else {
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    // http://leidu.jiaoyidazhuo.com/FileSystem/UploadFile
    //上传文档
    async uploadDocument(formData) {
      try {
        const response = await apiClient.post(`/FileSystem/UploadFile`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        if (response.data.code === 200) {
          return response.data
        } else {
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    // http://leidu.jiaoyidazhuo.com/FileSystem/invalid
    //作废文档
    async invalidDocument(params) {
      try {
        const response = await apiClient.post(`/FileSystem/invalid`, params, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
        if (response.data.code === 200) {
          return response.data
        } else {
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    // http://leidu.jiaoyidazhuo.com/FileSystem/download
    //下载文档
    async downloadDocument(params) {
      try {
        const response = await apiClient.post(`/FileSystem/download`, params, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
        if (response.data.code === 200) {
          return response.data
        } else {
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    // http://leidu.jiaoyidazhuo.com/FileSystem/deleteFileByIds
    //删除文档
    async deleteDocument(params) {
      try {
        const response = await apiClient.post(`/FileSystem/deleteFileByIds`, params, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
        if (response.data.code === 200) {
          return response.data
        } else {
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    // http://leidu.jiaoyidazhuo.com/FileSystem/AddNode
    //添加节点
    async addDocumentNode(params) {
      try {
        const response = await apiClient.post(`/FileSystem/AddNode`, params)
        if (response.data.code === 200) {
          return response.data
        } else {
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    // http://leidu.jiaoyidazhuo.com/FileSystem/deleteNode
    //删除节点
    async delDocumentNode(params) {
      try {
        const response = await apiClient.post(
          `/FileSystem/deleteNode?fileTypeid=` +
          params.fileTypeId +
          '&fileTypeName=' +
          params.fileTypeName +
          '&folderId=' +
          params.folderId +
          '&folderName=' +
          params.folderName,
          {}
        )
        if (response.data.code === 200) {
          return response.data
        } else {
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    //http://192.168.110.250:8281/register/order/list?t=1725551048956&visitType=0201&visitNo=1000025142
    async getOrders(params) {
      try {
        const response = await apiClient.get(`/register/order/list`, { params })
        if (response.data.code === 200) {
          //console.log("/register/order/list>" + response.data.msg);
          return response.data
        } else {
          //console.log(response.data.msg);
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    async updateReviewToPublish(value) {
      //更新审核完发布
      try {
        const response = await apiClient.put('/config/REVIEW_TO_PUBLISH', {
          configValue: value ? 'on' : 'off'
        })
        if (response.data.code === 200) {
          return response.data
        } else {
          throw new Error(response.data.msg)
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },

    async updateConfig(configName, configValue) {
      //更新配置
      try {
        const response = await apiClient.put(`/config/${configName}`, {
          configValue
        })
        if (response.data.code === 200) {
          return response.data
        } else {
          throw new Error(response.data.msg)
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    async rolelist() {
      //获取角色列表
      try {
        const response = await apiClient.get('/role/list')
        if (response.data.code === 200) {
          return response.data.data
        } else {
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    async getParameterList(params) {
      //参数管理
      try {
        const response = await apiClient.get('/parameter/list', { params })
        if (response.data.code === 200) {
          return response.data.data
        } else {
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    async addParameter(parameter) {
      //添加参数
      try {
        const response = await apiClient.post('/parameter/add', parameter)
        if (response.data.code === 200) {
          return response.data
        } else {
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.detail : error.message)
      }
    },
    async deleteParameter(id) {
      //删除参数
      try {
        const response = await apiClient.delete(`/parameter/${id}`)
        if (response.data.code === 200) {
          return response.data
        } else {
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.detail : error.message)
      }
    },
    //生成条码
    async accessionGenerate(credentials) {
      try {
        const response = await apiClient.put('/register/order/accession/generate', credentials)
        if (response.data.code == 200) {
          return response.data
        } else {
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.detail : error.message)
      }
    },
    async accessionRemove(credentials) {
      try {
        const response = await apiClient.put('/register/order/accession/remove', credentials)
        if (response.data.code == 200) {
          return response.data
        } else {
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.detail : error.message)
      }
    },

    //报告查询http://192.168.110.250:8281/sample/search?t=1725845928051&deviceId=6F1D3D80-02BD-EB11-80B6-005056925F97
    //&startCheckTime=1693497600&endCheckTime=1696089599&pageIndex=2&pageSize=50
    async getSamples(params) {
      try {
        const response = await apiClient.get(`/sample/search`, { params })
        if (response.data.code === 200) {
          //console.log("/sample/search>" + response.data.msg);
          return response.data
        } else {
          //console.log(response.data.msg);
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    async sampleList(params) {
      try {
        const response = await apiClient.get(`/sample/list`, { params })
        if (response.data.code === 200) {
          //console.log("/sample/list>" + response.data.msg);
          return response.data
        } else {
          //console.log(response.data.msg);
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    //http://192.168.110.250:8281/patient/list?accessionNum=2023326539 根据条码获取病人信息
    async getPatient(params) {
      try {
        const response = await apiClient.get(`/patient/list`, { params })
        if (response.data.code === 200) {
          //console.log("/patient/list>" + response.data.msg);
          return response.data
        } else {
          //console.log(response.data.msg);
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    //http://192.168.110.250:8281/patient/2023397250/params?nearTimes=10 获取最近3次检查数据
    async getRecentInfo(patientId, nearTimes) {
      try {
        const response = await apiClient.get(`/patient/${patientId}/params?nearTimes=${nearTimes}`)
        if (response.data.code === 200) {
          //console.log(response.data.msg);
          return response.data
        } else {
          //console.log(response.data.msg);
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    //sample/5121E73D-475E-EE11-8AC2-005056B71022/params
    async getSample(guID) {
      try {
        const response = await apiClient.get(`/sample/${guID}/params`)
        if (response.data.code === 200) {
          //console.log(response.data.msg);
          return response.data
        } else {
          //console.log(response.data.msg);
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    //通过id查询名字,http://192.168.110.250:8281/user/list?emplIds=6d459fe9-5afd-480f-b659-df8a174e7975&pageIndex=1&pageSize=1
    async getUser(params) {
      try {
        const response = await apiClient.get(`/user/list`, { params })
        if (response.data.code === 200) {
          //console.log("/user/list>" + response.data.msg);
          return response.data
        } else {
          //console.log(response.data.msg);
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },

    async fetchConfigList() {
      try {
        const response = await apiClient.get('/config/list')
        if (response.data.code === 200) {
          return response.data.data
        } else {
          throw new Error(response.data.msg)
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    //生成条码
    async sampleSave(credentials) {
      try {
        const response = await apiClient.post('/sample/save', credentials)
        if (response.data.code == 200) {
          return response.data
        } else {
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.detail : error.message)
      }
    },
    ///sample/update/status
    async sampleUpdate(credentials) {
      try {
        const response = await apiClient.put('/sample/update/status', credentials)
        if (response.data.code == 200) {
          return response.data
        } else {
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.detail : error.message)
      }
    },
    ///sample/update/status
    async sampleRecycle(credentials) {
      try {
        const response = await apiClient.post('/sample/Recycle', credentials)
        if (response.data.code == 200) {
          return response.data
        } else {
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.detail : error.message)
      }
    },
    //通过用户名关键字查询用户
    async patientSearch(keyword) {
      try {
        const response = await apiClient.get('/patient/search_key', {
          params: {
            searchKey: keyword // 将 searchKey 转换为查询参数
          }
        })
        if (response.data.code == 200) {
          return response.data
        } else {
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.detail : error.message)
      }
    },

    ///sample/28538D45-845D-EE11-8AC2-005056B71022/print打印报告
    async samplePrint(guID) {
      try {
        const response = await apiClient.get(`/sample/${guID}/print`, {
          responseType: 'blob'
        })
        return response
      } catch (error) {
        throw new Error('获取PDF文件失败: ' + error.message)
      }
    },
    //获取分析
    async sampleDiPOCAnalyze(params) {
      try {
        const response = await apiClient.post('/sample/DiPOCAnalyze', {}, { params: params })
        if (response.data.code == 200) {
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.msg : error.message)
      }
    },
    async criticalsearch(keyword) {
      try {
        const response = await apiClient.get('critical/search', {
          params: keyword // 将 searchKey 转换为查询参数
        })
        if (response.data.code == 200) {
          return response.data
        } else {
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.detail : error.message)
      }
    },

    async updatePatientFieldSettings(settings) {
      try {
        const response = await apiClient.put('/config/PATIENT_SHOW_COLUMN', { configValue: JSON.stringify(settings) })
        if (response.data.code === 200) {
          return response.data
        } else {
          throw new Error(response.data.msg)
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    async updateReportFieldsSettings(settings) {
      try {
        const response = await apiClient.put('/config/REPORT_VIEW_REPORTS_SHOW_COLUMN', {
          configType: 'SYSTEM',
          configValue: JSON.stringify(settings)
        })
        if (response.data.code === 200) {
          return response.data
        } else {
          throw new Error(response.data.msg)
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    async updateParamShowDotLen(dotLen) {
      try {
        const response = await apiClient.put('/config/PARAM_SHOW_DOTLEN', {
          configValue: dotLen
        })
        if (response.data.code === 200) {
          return response.data
        } else {
          throw new Error(response.data.msg)
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },

    async updateQueryFieldsSettings(settings) {
      try {
        const response = await apiClient.put('/config/REPORT_SEARCH_REPORTS_SHOW_COLUMN', {
          configValue: JSON.stringify(settings)
        })
        if (response.data.code === 200) {
          return response.data
        } else {
          throw new Error(response.data.msg)
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    async syncUser() {
      //同步用户
      try {
        const response = await apiClient.post('/user/sync', {
          orgCode: null
        })
        if (response.data.code === 200) {
          return response.data
        } else {
          throw new Error(response.data.msg)
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    async addUser(userData) {
      //添加用户
      try {
        const response = await apiClient.post('/user/add', userData)
        if (response.data.code === 200) {
          return response.data
        } else {
          throw new Error(response.data.msg)
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    async updateUser(userId, userData) {
      try {
        const response = await apiClient.put(`/user/${userId}`, userData)
        if (response.data.code === 200) {
          return response.data
        } else {
          throw new Error(response.data.msg)
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    async getRoleList() {
      try {
        const response = await apiClient.get(`/role/list`)
        if (response.data.code === '200') {
          //console.log(response.data.msg);
          return response.data
        } else {
          //console.log(response.data.msg);
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    async getQcProductList(params) {
      try {
        const response = await apiClient.get(`/qualityControlCenter/QcProducts/list`, { params })
        if (response.data.code === '200') {
          //console.log(response.data.msg);
          return response.data
        } else {
          //console.log(response.data.msg);
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    async GetQcParameterListByDeviceType(deviceTypeId) {
      try {
        const response = await apiClient.get(`/qualityControlCenter/${deviceTypeId}/parameters`)
        if (response.data.code === '200') {
          //console.log(response.data.msg);
          return response.data
        } else {
          //console.log(response.data.msg);
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    async getDeviceTypeList() {
      //获取设备类型列表
      try {
        const response = await apiClient.get(`/device/Types/list`)
        if (response.data.code === '200') {
          //console.log(response.data.msg);
          return response.data
        } else {
          //console.log(response.data.msg);
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    async getDictList(code) {
      try {
        const response = await apiClient.get(`/dict/list?typeCode=` + code)
        if (response.data.code === '200') {
          //console.log(response.data.msg);
          return response.data
        } else {
          //console.log(response.data.msg);
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },

    async putDictList(dictobj) {
      try {
        const response = await apiClient.put(`/dict/` + dictobj.id, dictobj)
        if (response.data.code === '200') {
          //console.log(response.data.msg);
          return response.data
        } else {
          //console.log(response.data.msg);
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    async getRangeDailyQualifyList(params) {
      try {
        const response = await apiClient.get(`/qualityControlCenter/RangeDailyQualify/list`, { params })
        if (response.data.code === '200') {
          //console.log(response.data.msg);
          return response.data
        } else {
          //console.log(response.data.msg);
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    async updateRangeDailyQualify(params) {
      try {
        const response = await apiClient.post(`/qualityControlCenter/Save`, params)
        if (response.data.code === '200') {
          //console.log(response.data.msg);
          return response.data
        } else {
          //console.log(response.data.msg);
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },

    async updateProductParams(params) {
      try {
        const response = await apiClient.post(`/qualityControlCenter/SaveDeviceProduceQcParams`, params)
        if (response.data.code === '200') {
          return response.data
        } else {
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    async deleteProductParams(params) {
      try {
        const response = await apiClient.post(`/qualityControlCenter/DeleteDeviceProduceQcParams`, params)
        if (response.data.code === '200') {
          return response.data
        } else {
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    async addQualityProduct(params) {
      try {
        const response = await apiClient.post(`/qualityControlCenter/Save/QcProducts`, params)
        if (response.data.code === '200') {
          //console.log(response.data.msg);
          return response.data
        } else {
          //console.log(response.data.msg);
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    async delQualityProduct(params) {
      try {
        const response = await apiClient.post(`/qualityControlCenter/Delete/QcProducts`, params)
        if (response.data.code === '200') {
          //console.log(response.data.msg);
          return response.data
        } else {
          //console.log(response.data.msg);
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    async getQcChannel(params) {
      try {
        const response = await apiClient.get(`/qualityControlCenter/GetQcChannels`, { params })
        if (response.data.code === '200') {
          //console.log(response.data.msg);
          return response.data
        } else {
          //console.log(response.data.msg);
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    async addQualityChannel(params) {
      try {
        const response = await apiClient.post(`/qualityControlCenter/SaveParametersQcChannels`, params)
        if (response.data.code === '200') {
          //console.log(response.data.msg);
          return response.data
        } else {
          //console.log(response.data.msg);
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    async delQualityChannel(params) {
      try {
        const response = await apiClient.post(`/qualityControlCenter/DeleteParametersQcChannels`, params)
        if (response.data.code === '200') {
          //console.log(response.data.msg);
          return response.data
        } else {
          //console.log(response.data.msg);
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    async setDefaultParameters(action, params) {
      try {
        const response = await apiClient.post(`/qualityControlCenter/SetDefaultParameters/` + action, params)
        if (response.data.code === 200) {
          return response.data
        } else {
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    async getQualityParamsList(params) {
      try {
        const response = await apiClient.post(`/qualityControlCenter/DeviceProduceQcParameters/List`, params)
        if (response.data.code === 200) {
          //console.log(response.data.msg);
          return response.data
        } else {
          //console.log(response.data.msg);
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    async getDailyQcValueList(params) {
      try {
        const response = await apiClient.get(`/qualityControlCenter/GetDailyQcValue`, { params })
        if (response.data.code.toString() === '200') {
          return response.data
        } else {
          console.log('err')
          throw new Error(response.data.msg)
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    async getCalculateRange(measurementCount, westgardRange) {
      try {
        const response = await apiClient.post(
          `/qualityControlCenter/RangeCalculator/` + measurementCount,
          westgardRange
        )
        if (response.data.code === 200) {
          return response.data
        } else {
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    async getFactoryQcValueList(params) {
      try {
        const response = await apiClient.get(`/qualityControlCenter/GetFactoryQcValue`, { params })
        if (response.data.code.toString() === '200') {
          return response.data
        } else {
          console.log('err')
          throw new Error(response.data.msg)
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    async getSampleMonitorList(params) {
      try {
        const response = await apiClient.get(`/DataStatistics/SampleMonitor/list`, { params })
        if (response.data.code === 200) {
          //console.log(response.data.msg);
          return response.data
        } else {
          //console.log(response.data.msg);
          throw new Error(response.data.msg)
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    async fetchDeviceList() {
      //同步设备
      try {
        const response = await apiClient.get('/device/sync/list')
        if (response.data.code === 200) {
          return response.data
        } else {
          throw new Error(response.data.msg)
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    async addDevice(devices) {
      //添加设备
      try {
        const response = await apiClient.post('/device/sync/save', devices)
        if (response.data.code === 200) {
          return response.data
        } else {
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.detail : error.message)
      }
    },
    async addRole(roleData) {
      //添加角色
      try {
        const response = await apiClient.post('/role/add', roleData)
        if (response.data.code === 200) {
          return response.data
        } else {
          throw new Error(response.data.msg)
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    async updateRole(roleId, roleData) {
      //更新角色
      try {
        const response = await apiClient.put(`/role/${roleId}`, roleData)
        if (response.data.code === 200) {
          return response.data
        } else {
          throw new Error(response.data.msg)
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    async getCurrentUser() {
      //获取当前登录用户信息
      try {
        const response = await apiClient.get('/user/current')
        if (response.data.code === 200) {
          return response.data.data
        } else {
          throw new Error(response.data.msg)
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    async deleteRole(roleId) {
      //删除角色
      try {
        const response = await apiClient.delete(`/role/${roleId}`)
        if (response.data.code === 200) {
          return response.data
        } else {
          throw new Error(response.data.msg)
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    async getDeviceParameters(deviceId) {
      //根据设备取出参数
      try {
        const response = await apiClient.get(`/device/${deviceId}/parameter`)
        if (response.data.code === 200) {
          return response.data
        } else {
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    async saveDeviceParameters(deviceId, paramId, parameter) {
      //修改设备参数
      try {
        const response = await apiClient.put(`/device/${deviceId}/parameter/${paramId}`, parameter)
        if (response.data.code === 200) {
          return response.data
        } else {
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    async updateDevice(deviceId, deviceData) {
      // 更新设备信息
      try {
        const response = await apiClient.put(`/device/${deviceId}`, deviceData)
        if (response.data.code === 200) {
          return response.data
        } else {
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.detail : error.message)
      }
    },
    async getSampleMonitorListWithParams(params) {
      try {
        const response = await apiClient.get(`/DataStatistics/sampleMonitorWithParameter/list`, { params })
        if (response.data.code === 200) {
          //console.log(response.data.msg);
          return response.data
        } else {
          //console.log(response.data.msg);
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    async getDataStatisticsViewList(params) {
      try {
        const response = await apiClient.get(`/DataStatistics/DataStatisticsView/list`, { params })
        if (response.data.code === 200) {
          //console.log(response.data.msg);
          return response.data
        } else {
          //console.log(response.data.msg);
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    async getStatisticCriticalList(params) {
      try {
        const response = await apiClient.get(`/DataStatistics/StatisticCritical/list`, { params })
        if (response.data.code === 200) {
          //console.log(response.data.msg);
          return response.data
        } else {
          //console.log(response.data.msg);
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    async getMeasureUncertaintyList(params) {
      try {
        const response = await apiClient.get(`/DataStatistics/MeasureUncertainty/list`, { params })
        if (response.data.code === 200) {
          //console.log(response.data.msg);
          return response.data
        } else {
          //console.log(response.data.msg);
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    async getDeviceParams(deviceIds) {
      try {
        const response = await apiClient.get(`/parameter/` + deviceIds + `/parameters`)
        if (response.data.code === 200) {
          //console.log(response.data.msg);
          return response.data
        } else {
          //console.log(response.data.msg);
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    async getConsumableReplaceList(params) {
      try {
        const response = await apiClient.get(`/DataStatistics/ConsumableReplace/list`, { params })
        if (response.data.code === '200') {
          //console.log(response.data.msg);
          return response.data
        } else {
          //console.log(response.data.msg);
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    async getDepartmentQCStatusList(params) {
      try {
        const response = await apiClient.get(`/DataStatistics/DepartmentQCStatus/list`, { params })
        if (response.data.code === '200') {
          //console.log(response.data.msg);
          return response.data
        } else {
          //console.log(response.data.msg);
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    async updateDictValue(id, params) {
      try {
        const response = await apiClient.put(`/dict/` + id, params)
        if (response.data.code === '200') {
          //console.log(response.data.msg);
          return response.data
        } else {
          //console.log(response.data.msg);
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    async getClinicReviewList(params) {
      try {
        const response = await apiClient.get(`/DataStatistics/ClinicReview/list`, { params })
        if (response.data.code === '200') {
          //console.log(response.data.msg);
          return response.data
        } else {
          //console.log(response.data.msg);
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    async getSampleComparisonList(params) {
      try {
        const response = await apiClient.get(`/DataStatistics/SampleComparison/list`, { params })
        if (response.data.code === '200') {
          //console.log(response.data.msg);
          return response.data
        } else {
          //console.log(response.data.msg);
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    async getLJChartData(params) {
      try {
        const response = await apiClient.get(`/qualityControlCenter/QcProducts/LJChartDataInfoList`, { params })
        if (response.data.code === '200') {
          //console.log(response.data.msg);
          return response.data
        } else {
          //console.log(response.data.msg);
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },

    async getTemplateList(params) {
      //打印模板列表
      try {
        const response = await apiClient.get('/template/list', { params })
        if (response.data.code === 200) {
          return response.data
        } else {
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    async updateTemplate(templateId, templateData) {
      //更新模板
      try {
        const response = await apiClient.put(`/template/${templateId}`, templateData)
        if (response.data.code === 200) {
          return response.data
        } else {
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    async deleteTemplate(templateId) {
      //删除模板
      try {
        const response = await apiClient.delete(`/template/${templateId}`)
        if (response.data.code === 200) {
          return response.data
        } else {
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    async getHospitalDepartments() {
      //获取所有科室
      try {
        const response = await apiClient.get('/hospital/departments')
        if (response.data.code === 200) {
          return response.data
        } else {
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    async updateDepartment(id, departmentData) {
      // 更新部门信息
      try {
        const response = await apiClient.put(`/hospital/department/${id}`, departmentData);
        if (response.data.code === 200) {
          return response.data;
        } else {
          return response.data;
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message);
      }
    },
    async addDepartment(departmentData) {
      try {
        const response = await apiClient.post('/hospital/department', departmentData);
        if (response.data.code === 200) {
          return response.data;
        } else {
          return response.data;
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message);
      }
    },
    async getClinicReviewSampleResult(params) {
      try {
        const response = await apiClient.get('/DataStatistics/SampleResult/list', { params })
        if (response.data.code === 200) {
          return response.data
        } else {
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    async syncHospitalDepartments() {
      //同步科室
      try {
        const response = await apiClient.post('/hospital/sync/department')
        if (response.data.code === 200) {
          return response.data
        } else {
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    async getLJChartDataParams(params) {
      //同步科室
      try {
        const response = await apiClient.get('/qualityControlCenter/QcProducts/LJChartDataDetailList', { params })
        if (response.data.code === 200) {
          return response.data
        } else {
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    async getRangeSetting(params) {
      try {
        const response = await apiClient.get('/qualityControlCenter/RangeDailyQualify/list', { params })
        if (response.data.code === 200) {
          return response.data
        } else {
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    async updateRangeSetting(params) {
      try {
        const response = await apiClient.post('/qualityControlCenter/SaveRangeSetting', params)
        if (response.data.code === 200) {
          return response.data
        } else {
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    async getDeviceQCParams(deviceIds) {
      try {
        const response = await apiClient.get(`/parameter/` + deviceIds + `/qcParameters`)
        if (response.data.code === 200) {
          //console.log(response.data.msg);
          return response.data
        } else {
          //console.log(response.data.msg);
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    async getDeviceReagentTypes(deviceIds) {
      try {
        const response = await apiClient.get(`/qualityControlCenter/` + deviceIds + `/ReagentTypes`)
        if (response.data.code === 200) {
          //console.log(response.data.msg);
          return response.data
        } else {
          //console.log(response.data.msg);
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    async getZScoreData(params) {
      try {
        const response = await apiClient.get(`/qualityControlCenter/QcProducts/AnalysisChartInfo`, { params })
        if (response.data.code === 200) {
          //console.log(response.data.msg);
          return response.data
        } else {
          //console.log(response.data.msg);
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    async getWestGardRanges(params) {
      try {
        const response = await apiClient.get(`/qualityControlCenter/GetWestgardRangesById`, { params })
        if (response.data.code === 200) {
          //console.log(response.data.msg);
          return response.data
        } else {
          //console.log(response.data.msg);
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    async saveWestGardRanges(params) {
      try {
        const response = await apiClient.post(`/qualityControlCenter/SaveWestgardRanges`, params)
        if (response.data.code === '200') {
          return response.data
        } else {
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    async getLostHandlerIssueTemplates(params) {
      try {
        const response = await apiClient.get(`/qualityControlCenter/GetIssueTemplatesByDeviceTypeId`, { params })
        if (response.data.code === '200') {
          return response.data
        } else {
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    async saveQcAnalysisIssueCorrectiveAction(params) {
      try {
        const response = await apiClient.post(`/qualityControlCenter/SaveQcAnalysisIssueCorrectiveAction`, params)
        if (response.data.code === '200') {
          return response.data
        } else {
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    // 获取字典列表
    async getDictList() {
      try {
        const response = await apiClient.get('/dict/list')
        if (response.data.code === 200) {
          return response.data
        } else {
          throw new Error(response.data.msg)
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    setToken(token, user) {
      this.token = token
      localStorage.setItem('token', token) // 将 token 存储在 localStorage 中
      localStorage.setItem('user', user)
    },
    clearToken() {
      this.token = null
      this.user = null
      this.loginTime = null
      localStorage.removeItem('token') // 清除 localStorage 中的 token
      localStorage.removeItem('user')
      localStorage.removeItem('loginTime')
    },
    loadToken() {
      this.token = localStorage.getItem('token')
      this.user = localStorage.getItem('user')
    },
    isTokenExpired() {
      if (!this.token) return true
      try {
        const decoded = jwtDecode(this.token)
        const currentTime = Date.now() / 1000
        return decoded.exp < currentTime
      } catch (error) {
        return true
      }
    },
    async getDictTypeList() {
      try {
        const response = await apiClient.get('/dict/type/list')
        if (response.data.code === 200) {
          return response.data
        } else {
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    async getDictListByTypeCode(typeCode) {
      //根据类型编码获取字典列表
      try {
        const response = await apiClient.get(`/dict/list?typeCode=${typeCode}`)
        if (response.data.code === 200) {
          return response.data
        } else {
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    async updateDictType(typeCode, typeData) {
      //更新字典类型
      try {
        const response = await apiClient.put(`/dict/type/${typeCode}`, typeData)
        if (response.data.code === 200) {
          return response.data
        } else {
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    async addDictType(typeData) {
      //添加字典类型
      try {
        const response = await apiClient.post('/dict/type/add', typeData)
        if (response.data.code === 200) {
          return response.data
        } else {
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    async deleteDictType(typeCode) {
      //删除字典类型
      try {
        const response = await apiClient.delete(`/dict/type/${typeCode}`)
        if (response.data.code === 200) {
          return response.data
        } else {
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    async addDictItem(itemData) {
      //添加字典项
      try {
        const response = await apiClient.post('/dict/add', itemData)
        if (response.data.code === 200) {
          return response.data
        } else {
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    async updateDictItem(id, itemData) {
      //更新字典项
      try {
        const response = await apiClient.put(`/dict/${id}`, itemData)
        return response.data
      } catch (error) {
        console.error('Failed to update dictionary item:', error)
        throw error
      }
    },
    async deleteDictItem(id) {
      //删除字典项
      try {
        const response = await apiClient.delete(`/dict/${id}`)
        return response.data
      } catch (error) {
        console.error('Failed to delete dictionary item:', error)
        throw error
      }
    },
    async getDeviceQualityStatus(deviceId) {
      //获取设备是否质控
      try {
        const response = await apiClient.get(`/device/${deviceId}/quality`)
        if (response.data.code === 200) {
          return response.data // 直接返回 response.data
        } else {
          throw new Error(response.data.msg)
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    async deleteSamples(samples) {
      //删除样本
      try {
        const response = await apiClient.post('/sample/deleteSample', { samples })
        if (response.data.code === 200) {
          return response.data
        } else {
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    async getMergedList(params) {
      //获取待合并样本列表
      try {
        const response = await apiClient.get(`/sample/merge/list`, { params })
        if (response.data.code === 200) {
          return response.data
        } else {
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    async mergeSamples(params) {
      // 合并样本
      try {
        const response = await apiClient.put('/sample/merge', params)
        if (response.data.code === 200) {
          return response.data
        } else {
          throw new Error(response.data.msg)
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    // 更新 settings
    updateSettings(newSettings) {
      this.settings = newSettings;
    },
    async changePassword(payload) {//修改密码
      try {
        const response = await apiClient.put('/user/password', payload);
        console.log("response:", response);
        if (response.data.code === 200) {
          return response.data;
        } else {
          return response.data;
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message);
      }
    },
    async uploadFile(file) {
      try {
        const formData = new FormData();
        formData.append('file', file);
        const response = await apiClient.post('/file/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        if (response.data.code === 200) {
          return response.data; // 假设返回的文件地址在 data.fileUrl 中
        } else {
          throw new Error(response.data.msg);
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message);
      }
    },
    async addTemplate(templateData) {
      try {
        const response = await apiClient.post('/template/add', templateData);
        if (response.data.code === 200) {
          return response.data;
        } else {
          throw new Error(response.data.msg);
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message);
      }
    },
    async getTemplatePDF(templateId) {
      try {
        const response = await apiClient.get(`/template/${templateId}/pdf`, {
          responseType: 'blob' // 确保返回的是二进制数据
        });
        if (response.status === 200) {
          return response.data;
        } else {
          throw new Error('Failed to fetch PDF');
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message);
      }
    },
    async accessionPrint(credentials) {
      try {
        const response = await apiClient.get(`/register/order/accession/print`, {
          params: credentials,  // 将整个对象作为查询参数
          responseType: 'blob'  // 确保返回二进制数据
        })
        return response.data
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    async getDeviceExParameters(deviceId) {
      try {
        const response = await apiClient.get(`/device/ex/${deviceId}/parameter`)
        if (response.data.code === 200) {
          return response.data
        } else {
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
    async saveDeviceParameter(deviceId, parameter) {//同步后,保存设备参数
      try {
        const response = await apiClient.post(`/device/${deviceId}/parameter`, parameter)
        if (response.data.code === 200) {
          return response.data
        } else {
          return response.data
        }
      } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message)
      }
    },
  },
  getters: {
    isAuthenticated: (state) => !!state.token && !state.isTokenExpired(),
    getToken: (state) => state.token,
    getSettings: (state) => state.settings,
    getUserData: (state) => state.userData
  }
})
