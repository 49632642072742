// src/store/settings.js
import { defineStore } from 'pinia';
import { useAuthStore } from '@/stores/authStore';

export const useSettingsStore = defineStore('settings', {
    state: () => ({
        settings: {
            EMPLOYEE_PASSWORD_TIMEOUT: 366,
            EMPLOYEE_PASSWORD_ERROR_LOCK_COUNT: 10,
            dotLen: 4,
            SYSTEM_CA_USED: true,
            publishEnabled: false,
            sh: true,
            selfsh: true,
            imgs: false,
            patientFieldSettings: [],
            reportFieldsSettings: [],
            queryFieldsSettings: [],
            USER_LOGIN_TOKEN_TIMEOUT: 24,
            reviewBeforePrint: false,
            allowPrintWithNoPay: false,
            patientPrintInterfaceAccountPass: {},
            systemFileUploadAddress: '',
            systemTranslateReportPdf: {},
            systemTemplatePrintSetting: [],
            systemLogoBase64: '',
            systemRegisterGroupDictTypeCode: '',
            systemOrderBarcodePrintTemplateAddr: '',
            recycleReason: false,
            filesystemMenu: [],
            filesystemStoreageDerect: '',
            filesystemDownloadAddress: ''
        },
        dialogVisible: {
            patientField: false,
            reportFields: false,
            queryFields: false,
        },
    }),
    actions: {
        async fetchSettings() {
            const authStore = useAuthStore();
            try {
                const configList = await authStore.fetchConfigList();
                configList.forEach(config => {
                    switch (config.configName) {
                        case 'REVIEW_TO_PUBLISH':
                            this.settings.publishEnabled = config.configValue === 'on';
                            break;
                        case 'REVIEWER_DIFF_CHECKER':
                            this.settings.sh = config.configValue === 'on';
                            break;
                        case 'CANCEL_OWNER_REVIEW':
                            this.settings.selfsh = config.configValue === 'on';
                            break;
                        case 'SIGN_WITH_IMAGE':
                            this.settings.imgs = config.configValue === 'on';
                            break;
                        case 'USER_LOGIN_TOKEN_TIMEOUT':
                            this.settings.USER_LOGIN_TOKEN_TIMEOUT = parseInt(config.configValue, 10);
                            break;
                        case 'PATIENT_SHOW_COLUMN':
                            this.settings.patientFieldSettings = JSON.parse(config.configValue);
                            break;
                        case 'REPORT_VIEW_REPORTS_SHOW_COLUMN':
                            this.settings.reportFieldsSettings = JSON.parse(config.configValue);
                            break;
                        case 'REPORT_SEARCH_REPORTS_SHOW_COLUMN':
                            this.settings.queryFieldsSettings = JSON.parse(config.configValue);
                            break;
                        case 'PARAM_SHOW_DOTLEN':
                            this.settings.dotLen = config.configValue;
                            break;
                        case 'EMPLOYEE_PASSWORD_TIMEOUT':
                            this.settings.EMPLOYEE_PASSWORD_TIMEOUT = parseInt(config.configValue, 10);
                            break;
                        case 'EMPLOYEE_PASSWORD_ERROR_LOCK_COUNT':
                            this.settings.passwordAttempts = parseInt(config.configValue, 10);
                            break;
                        case 'PATIENT_PRINT_INTERFACE_ACCOUNT_PASS':
                            this.settings.patientPrintInterfaceAccountPass = JSON.parse(config.configValue);
                            break;
                        case 'SYSTEM_FILE_UPLOAD_ADDRESS':
                            this.settings.systemFileUploadAddress = config.configValue;
                            break;
                        case 'SYSTEM_TRANSLATE_REPORT_PDF':
                            this.settings.systemTranslateReportPdf = JSON.parse(config.configValue);
                            break;
                        case 'SYSTEM_TEMPLATE_PRINT_SETTING':
                            this.settings.systemTemplatePrintSetting = JSON.parse(config.configValue);
                            break;
                        case 'SYSTEM_LOGO_BASE64':
                            this.settings.systemLogoBase64 = config.configValue;
                            break;
                        case 'SYSTEM_CA_USED':
                            this.settings.SYSTEM_CA_USED = config.configValue === 'on';
                            break;
                        case 'SYSTEM_REGISTER_GROUP_DICT_TYPE_CODE':
                            this.settings.systemRegisterGroupDictTypeCode = config.configValue;
                            break;
                        case 'SYSTEM_ORDER_BARCODE_PRINT_TEMPLATE_ADDR':
                            this.settings.systemOrderBarcodePrintTemplateAddr = config.configValue;
                            break;
                        case 'RECYCLE_REASON':
                            this.settings.recycleReason = config.configValue === 'on';
                            break;
                        case 'FILESYSTEM_MENU':
                            this.settings.filesystemMenu = JSON.parse(config.configValue);
                            break;
                        case 'FILESYSTEM_STOREAGE_DERECT':
                            this.settings.filesystemStoreageDerect = config.configValue;
                            break;
                        case 'FILESYSTEM_DOWNLOAD_ADDRESS':
                            this.settings.filesystemDownloadAddress = config.configValue;
                            break;
                        default:
                            break;
                    }
                });
            } catch (error) {
                console.error('Error fetching settings:', error);
            }
        },
        async saveSettings(type) {
            const authStore = useAuthStore();
            try {
                let response;
                if (type === 'patientField') {
                    response = await authStore.updatePatientFieldSettings(this.settings.patientFieldSettings);
                } else if (type === 'reportFields') {
                    response = await authStore.updateReportFieldsSettings(this.settings.reportFieldsSettings);
                } else if (type === 'queryFields') {
                    response = await authStore.updateQueryFieldsSettings(this.settings.queryFieldsSettings);
                } else if (type === 'paramShowDotLen') {
                    response = await authStore.updateParamShowDotLen(this.settings.dotLen);
                } else if (type === 'reviewToPublish') {
                    response = await authStore.updateReviewToPublish(this.settings.publishEnabled);
                }
                this.dialogVisible[type] = false; // 关闭对话框
            } catch (error) {
                console.error('保存字段设置失败', error);
            }
        },
        async saveSettings2(configName, configValue) {
            const authStore = useAuthStore();
            try {
                const response = await authStore.updateConfig(configName, configValue);
                if (response.code === 200) {
                    console.log(`保存 ${configName} 设置成功`);
                } else {
                    console.error(`保存 ${configName} 设置失败:`, response.msg);
                }
            } catch (error) {
                console.error(`保存 ${configName} 设置异常:`, error);
            }
        },
        async increment(field) {
            this.settings[field]++;
            await this.saveSettings('paramShowDotLen');
        },
        async decrement(field) {
            if (this.settings[field] > 0) {
                this.settings[field]--;
                await this.saveSettings('paramShowDotLen');
            }
        },
        openDialog(type) {
            this.dialogVisible[type] = true;
        },
        closeDialog(type) {
            this.dialogVisible[type] = false;
        },
        incrementWidth(row) {
            row.width += 10;
        },
        decrementWidth(row) {
            if (row.width > 10) {
                row.width -= 10;
            }
        },
    },
});
