// src/utils/defaultPermissions.js
export const defaultPermissions = {
  menus: [
    {
      name: 'DataCenter',
      desc: '数据中心',
      hasPermission: true,
      children: [
        {
          name: 'DataStatistics',
          desc: '质控数据统计',
          hasPermission: true
        },
        {
          name: 'SampleMonitor',
          desc: '样本检测量统计',
          hasPermission: true
        },
        {
          name: 'ReplacementReport',
          desc: '耗材更换记录',
          hasPermission: true
        },
        {
          name: 'DataCritical',
          desc: '异常值统计',
          hasPermission: true
        },
        {
          name: 'DeviceData',
          desc: '设备质控概览',
          hasPermission: true
        },
        {
          name: 'MeasureUncertainty',
          desc: '测量不确定度',
          hasPermission: true
        },
        {
          name: 'ClinicReview',
          desc: '临床调阅',
          hasPermission: true
        }
      ]
    },
    {
      name: 'QualityCenter',
      desc: '质控中心',
      hasPermission: true,
      children: [
        {
          name: 'QualityMgr',
          desc: '质控管理',
          hasPermission: true,
          children: [
            {
              name: 'ZScore',
              desc: 'Z分数图',
              hasPermission: true
            },
            {
              name: 'ProductChart',
              desc: 'L-J质控图',
              hasPermission: true
            },
            {
              name: 'QualityProduct',
              desc: '质控品管理',
              hasPermission: true
            },
            {
              name: 'QualityChannel',
              desc: '质控通道管理',
              hasPermission: true
            },
            {
              name: 'QualityRange',
              desc: '质控范围设置',
              hasPermission: true
            }
          ]
        },
        {
          name: 'InstrumentVerification',
          desc: '仪器性能验证',
          hasPermission: true,
          children: [
            {
              name: 'InstrumentAnalysis',
              desc: '性能验证分析',
              hasPermission: true
            },
            {
              name: 'InstrumentSetting',
              desc: '验证参数设置',
              hasPermission: true
            }
          ]
        },
        {
          name: 'ComparisonTarget',
          desc: '靶机对比',
          hasPermission: true,
          children: [
            {
              name: 'ComparisonScheme',
              desc: '比对方案',
              hasPermission: true
            },
            {
              name: 'ComparisonPlan',
              desc: '比对计划',
              hasPermission: true
            }
          ]
        },
        {
          name: 'ManualInput',
          desc: '手工录入',
          hasPermission: true,
          children: [
            {
              name: 'SingleEnter',
              desc: '单次录入',
              hasPermission: true
            },
            {
              name: 'BatchEnter',
              desc: '批量导入',
              hasPermission: true
            }
          ]
        }
      ]
    },
    {
      name: 'Report',
      desc: '样本中心',
      hasPermission: true,
      children: [
        {
          name: 'ReportRegister',
          desc: '登记',
          hasPermission: true
        },
        {
          name: 'ReportView',
          desc: '报告',
          hasPermission: true,
          elements: [
            {
              name: 'invalid',
              desc: '作废',
              hasPermission: true
            },
            {
              name: 'restored',
              desc: '恢复',
              hasPermission: true
            },
            {
              name: 'saved',
              desc: '保存',
              hasPermission: true
            },
            {
              name: 'audited',
              desc: '审核',
              hasPermission: true
            },
            {
              name: 'cancelAudited',
              desc: '取消审核',
              hasPermission: true
            },
            {
              name: 'publish',
              desc: '发布',
              hasPermission: true
            },
            {
              name: 'recycle',
              desc: '回收',
              hasPermission: true
            },
            {
              name: 'print',
              desc: '打印',
              hasPermission: true
            },
            {
              name: 'deleted',
              desc: '删除',
              hasPermission: true
            }
          ]
        },
        {
          name: 'ReportSearch',
          desc: '查询',
          hasPermission: true,
          elements: [
            {
              name: 'invalid',
              desc: '作废',
              hasPermission: true
            },
            {
              name: 'restored',
              desc: '恢复',
              hasPermission: true
            },
            {
              name: 'saved',
              desc: '保存',
              hasPermission: true
            },
            {
              name: 'audited',
              desc: '审核',
              hasPermission: true
            },
            {
              name: 'cancelAudited',
              desc: '取消审核',
              hasPermission: true
            },
            {
              name: 'publish',
              desc: '发布',
              hasPermission: true
            },
            {
              name: 'recycle',
              desc: '回收',
              hasPermission: true
            },
            {
              name: 'print',
              desc: '打印',
              hasPermission: true
            },
            {
              name: 'deleted',
              desc: '删除',
              hasPermission: true
            }
          ]
        },
        {
          name: 'ReportCrisis',
          desc: '危急值',
          hasPermission: true
        },
        {
          name: 'ReportSetting',
          desc: '报告设置',
          hasPermission: true
        }
      ]
    },
    {
      name: 'FileSystem',
      desc: '文档管理',
      hasPermission: true,
      children: [
        {
          name: 'File',
          desc: '文档',
          hasPermission: true,
          elements: [
            {
              name: 'addnode',
              desc: '添加节点',
              hasPermission: true
            },
            {
              name: 'deletenode',
              desc: '删除节点',
              hasPermission: true
            },
            {
              name: 'editnode',
              desc: '编辑节点',
              hasPermission: true
            },
            {
              name: 'deletefile',
              desc: '删除文件',
              hasPermission: true
            },
            {
              name: 'invalidfile',
              desc: '作废文件',
              hasPermission: true
            },
            {
              name: 'uploadfile',
              desc: '上传文件',
              hasPermission: true
            },
            {
              name: 'downloadfile',
              desc: '下载文件',
              hasPermission: true
            }
          ]
        }
      ]
    },
    {
      name: 'Settings',
      desc: '系统设置',
      hasPermission: true,
      children: [
        {
          name: 'ReportSettings',
          desc: '报告系统设置',
          hasPermission: true
        },
        {
          name: 'UserSettings',
          desc: '用户管理',
          hasPermission: true
        },
        {
          name: 'StandardParamsSettings',
          desc: '参数管理',
          hasPermission: true
        },
        {
          name: 'RoleSettings',
          desc: '角色管理',
          hasPermission: true
        },
        {
          name: 'TemplateSettings',
          desc: '打印模板',
          hasPermission: true
        },
        {
          name: 'DepartmentSettings',
          desc: '科室管理',
          hasPermission: true
        },
        {
          name: 'DeviceSettings',
          desc: '设备管理',
          hasPermission: true
        },
        {
          name: 'DictSettings',
          desc: '字典管理',
          hasPermission: true
        }
      ]
    }
  ]
}
