<template>
  <el-dialog
      title="角色信息"
      v-model="dialogVisible"
      width="30%"
      :close-on-click-modal="true"
      @close="handleClose"
  >
    <el-form :model="role" ref="roleForm" label-width="80px">
      <el-form-item label="角色名称">
        <el-input v-model="role.name" placeholder="请输入角色名称"></el-input>
      </el-form-item>
    </el-form>

    <!-- 弹出窗口底部按钮 -->
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取消</el-button>
      <el-button type="primary" @click="saveRole">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { ref, watch } from 'vue';
import { cloneDeep } from 'lodash'; // 使用 lodash 的 cloneDeep 进行深拷贝

export default {
  props: {
    role: {
      type: Object,
      default: () => ({ name: '' })
    }
  },
  setup(props, { emit }) {
    const dialogVisible = ref(true); // 控制弹窗显示
    const role = ref(cloneDeep(props.role || { name: '' })); // 深拷贝 role，如果 props.role 为 null，则初始化为 { name: '' }

    watch(
        () => props.role,
        (newRole) => {
          role.value = cloneDeep(newRole || { name: '' }); // 当 props.role 变化时，更新 role
        }
    );

    const handleClose = () => {
      dialogVisible.value = false;
      emit('close');
    };

    const saveRole = () => {
      emit('save', role.value);
      dialogVisible.value = false;
    };

    return {
      dialogVisible,
      role,
      handleClose,
      saveRole
    };
  }
};
</script>

<style scoped>
.dialog-footer {
  display: flex;
  justify-content: flex-end; /* 将按钮向右对齐 */
}
</style>
