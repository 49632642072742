<template>
  <div class="parameter-management-container">
    <div class="parameter-management-box">
      <div class="header">
        <span class="title">标准参数</span>
        <el-input v-model="search" placeholder="请输入内容" class="search-input" clearable>
          <template #prefix>
            <el-icon>
              <Search />
            </el-icon>
          </template>
        </el-input>
        <el-button type="primary" @click="showAddDialog" class="add-button">添加参数</el-button>
      </div>
      <div class="table-container">
        <el-table :data="paginatedParameters" class="custom-table" :header-cell-style="{ background: '#F5F6F7' }">
          <el-table-column prop="id" label="序号" width="auto" min-width="20%" align="left"
            header-align="left"></el-table-column>
          <el-table-column prop="code" label="参数代码" width="auto" min-width="20%" align="left"
            header-align="left"></el-table-column>
          <el-table-column prop="name" label="参数名称" width="auto" min-width="30%" align="left"
            header-align="left"></el-table-column>
          <el-table-column prop="createDate" label="创建时间" width="auto" min-width="30%" align="left" header-align="left"
            :formatter="formatDate">
          </el-table-column>

          <el-table-column label="操作" width="auto" min-width="20%" align="center" header-align="center">
            <template #default="scope">
              <el-button @click="deleteParameter(scope.row.id)" type="button" size="16" class="action1">
                <el-icon>
                  <Delete />
                </el-icon> 删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination-container">
        <el-pagination background layout="prev, pager, next" :total="total" :page-size="pageSize"
          @current-change="handlePageChange" class="pagination" />
        <div class="pagination-info">
          共 {{ total }} 条
        </div>
      </div>
    </div>

    <!-- 添加参数对话框 -->
    <el-dialog title="添加参数" v-model="addDialogVisible" width="360px" :style="{ borderRadius: '8px', height: '212px' }">
      <el-form :model="form">
        <el-form-item label="参数代码">
          <el-input v-model="form.paramCode" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="参数名称">
          <el-input v-model="form.paramName" placeholder="请输入"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false" style="width: 88px;">关闭</el-button>
        <el-button type="primary" @click="saveParameter" style="width: 88px;">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Search, Delete } from '@element-plus/icons-vue';
import { useAuthStore } from '@/stores/authStore';
import dayjs from 'dayjs';
import debounce from 'lodash.debounce';
export default {
  components: {
    Search,
    Delete
  },
  data() {
    return {
      search: '',
      addDialogVisible: false,
      form: {
        paramCode: '',
        paramName: ''
      },
      parameters: [], // Initialize as empty array
      currentPage: 1,
      pageSize: 10,
      total: 0,
    };
  },
  watch: {
    search: {
      handler: debounce(function () {
        this.currentPage = 1; // 重置到第一页
        this.fetchParameters(); // 触发获取参数
      }, 300), // Debounce for 300ms
      immediate: true // 组件初始时也触发一次
    }
  },
  computed: {
    filteredParameters() {
      //this.fetchParameters(); // Fetch parameters with search key
      return this.parameters;
    },
    paginatedParameters() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = this.currentPage * this.pageSize;
      return this.filteredParameters.slice(start, end);
    }
  },
  methods: {
    formatDate(row, column, cellValue) {
      if (cellValue) {
        return dayjs(cellValue).format('YYYY-MM-DD HH:mm:ss');
      }
      return '';
    },
    fetchParameters: debounce(async function () {
      const authStore = useAuthStore();
      const params = {
        searchKey: this.search,
        pageIndex: this.currentPage,
        pageSize: this.pageSize
      };
      try {
        const response = await authStore.getParameterList(params);
        this.parameters = response.data;
        this.total = response.total;
      } catch (error) {
        console.error('Failed to fetch parameters:', error);
      }
    }, 300), // Debounce for 300ms
    showAddDialog() {
      this.addDialogVisible = true;
    },
    async saveParameter() {
      if (!this.form.paramCode || !this.form.paramName) {
        this.$message.error('参数代码和参数名称不能为空。');
        return;
      }
      const authStore = useAuthStore();
      const newParameter = {
        code: this.form.paramCode,
        name: this.form.paramName,
      };
      try {
        const response = await authStore.addParameter(newParameter);
        if (response.code === 200) {
          this.parameters.push(newParameter);
          this.addDialogVisible = false;
          this.form.paramCode = '';
          this.form.paramName = '';
        } else {
          console.error('Failed to add parameter:', response.msg);
        }
      } catch (error) {
        console.error('Failed to add parameter:', error);
      }
    },
    handlePageChange(page) {
      this.currentPage = page;
      this.fetchParameters(); // Fetch new page of parameters
    },
    async deleteParameter(id) {
      const authStore = useAuthStore();
      try {
        const response = await authStore.deleteParameter(id);
        if (response.code === 200) {
          // Remove the parameter from the local parameters array
          this.parameters = this.parameters.filter(param => param.id !== id);
        } else {
          console.error('Failed to delete parameter:', response.msg);
        }
      } catch (error) {
        console.error('Failed to delete parameter:', error);
      }
    },
  },
  mounted() {
    this.fetchParameters(); // Fetch parameters on component mount
  }
};
</script>

<style scoped>
/* Existing styles... */
</style>


<style scoped>
* {
  box-sizing: border-box;
  /* 确保 padding 不会增加元素的总高度 */
}

.parameter-management-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* 居左布局 */
  background-color: #474747;
  min-height: 100vh;
  font-size: 16px;
  line-height: 20px;
}

.parameter-management-box {
  width: calc(100% - 20px);
  /* 减去左右padding */
  height: calc(100vh - 40px);
  /* 减去上下padding */
  background-color: #F0F3F5;
  border-radius: 12px;
  padding: 20px 20px 0 20px;
  box-sizing: border-box;
  position: relative;
  margin: 20px 20px 20px 0px;
  /* 距离顶部20px */
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.title {
  color: #057AB2;
  font-size: 16px;
  font-weight: 500;
  margin-right: auto;
}

.search-input {
  width: 300px;
  margin-right: 20px;
}

.add-button {
  margin-left: 20px;
  background-color: #057AB2;
  /* 上传模板按钮的背景色 */
  border-color: #057AB2;
}

.add-button:hover {
  background-color: #005f8a;
  /* 上传模板按钮悬停时的背景色 */
  border-color: #005f8a;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 10px;
}

.table-container {
  flex: 1;
  overflow: auto;
  position: relative;
}

.custom-table {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 52px;
  /* 为分页预留空间 */
  overflow: auto;
}

.custom-table .action1 {
  border: none;
  color: #057ab2;
  background-color: transparent;
}

.custom-table .action1:hover {
  background-color: rgba(5, 122, 178, 0.25);
  border: 1px solid rgba(5, 122, 178, 0.25);
  color: #057ab2;
}

.header-back {
  background-color: #DCDDDE;
  /* 表头背景色 */
}

.pagination-container {
  height: 52px;
  /* 与表格底部预留空间匹配 */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.pagination {
  display: flex;
  align-items: center;
}

.pagination-info {
  text-align: center;
  margin-left: 10px;
}
</style>
