<template>
  <div class="setting-container">
    <div class="setting-box">
      <div class="setting-header">
        <div class="header-line"></div>
        <h1>设置</h1>
      </div>
      <hr />
      <div class="setting-item">
        <span class="item-text">密码有效期（<span class="sub-text">天</span>）</span>
        <div class="control">
          <el-button class="control-button" type="default"
            @click="decrement('EMPLOYEE_PASSWORD_TIMEOUT', settings.EMPLOYEE_PASSWORD_TIMEOUT)"><el-icon size="14">
              <Minus />
            </el-icon></el-button>
          <span>{{ settings.EMPLOYEE_PASSWORD_TIMEOUT }}</span>
          <el-button class="control-button" type="default"
            @click="increment('EMPLOYEE_PASSWORD_TIMEOUT', settings.EMPLOYEE_PASSWORD_TIMEOUT)"><el-icon size="14">
              <Plus />
            </el-icon></el-button>
        </div>
      </div>
      <div class="setting-item">
        <span class="item-text">用户密码允许错误次数（<span class="sub-text">超过错误次数用户将被锁定</span>）</span>
        <div class="control">
          <el-button class="control-button" type="default"
            @click="decrement('EMPLOYEE_PASSWORD_ERROR_LOCK_COUNT', settings.EMPLOYEE_PASSWORD_ERROR_LOCK_COUNT)"><el-icon
              size="14">
              <Minus />
            </el-icon></el-button>
          <span>{{ settings.EMPLOYEE_PASSWORD_ERROR_LOCK_COUNT }}</span>
          <el-button class="control-button" type="default"
            @click="increment('EMPLOYEE_PASSWORD_ERROR_LOCK_COUNT', settings.EMPLOYEE_PASSWORD_ERROR_LOCK_COUNT)"><el-icon
              size="14">
              <Plus />
            </el-icon></el-button>
        </div>
      </div>
      <div class="setting-item">
        <span class="item-text">用户登录超时时间（小时）（<span class="sub-text">超时后将自动退出</span>）</span>
        <div class="control">
          <el-button class="control-button" type="default"
            @click="decrement('USER_LOGIN_TOKEN_TIMEOUT', settings.USER_LOGIN_TOKEN_TIMEOUT)"><el-icon size="14">
              <Minus />
            </el-icon></el-button>
          <span>{{ settings.USER_LOGIN_TOKEN_TIMEOUT }}</span>
          <el-button class="control-button" type="default"
            @click="increment('USER_LOGIN_TOKEN_TIMEOUT', settings.USER_LOGIN_TOKEN_TIMEOUT)"><el-icon size="14">
              <Plus />
            </el-icon></el-button>
        </div>
      </div>
      <div class="setting-item">
        <span class="item-text">上传LOGO（<span class="sub-text">上传系统LOGO图片</span>）</span>
        <el-button class="setting-button" type="default" @click="uploadLogo">
          设置 <el-icon class="setting-arrow"><arrow-right /></el-icon>
        </el-button>
        <input type="file" ref="logoInput" style="display: none;" @change="handleFileChange" />
      </div>
      <div class="setting-item">
        <span class="item-text">启用CA认证</span>
        <el-switch v-model="settings.SYSTEM_CA_USED"
          @change="saveSettings('SYSTEM_CA_USED', settings.SYSTEM_CA_USED ? 'on' : 'off')"></el-switch>
      </div>
    </div>
  </div>
</template>

<script>
import { ArrowRight, Plus, Minus } from '@element-plus/icons-vue';
import { useSettingsStore } from '@/store/settings';

export default {
  name: 'SystemSettings',
  components: {
    ArrowRight,
    Plus,
    Minus
  },
  setup() {
    const settingsStore = useSettingsStore();

    return {
      settingsStore,
    };
  },
  data() {
    return {
      settings: {
        EMPLOYEE_PASSWORD_TIMEOUT: 366,
        EMPLOYEE_PASSWORD_ERROR_LOCK_COUNT: 10,
        USER_LOGIN_TOKEN_TIMEOUT: 24,
        SYSTEM_CA_USED: true
      }
    };
  },
  methods: {
    async increment(field, value) {
      this.settings[field]++;
      await this.saveSettings(field, this.settings[field]);
    },
    async decrement(field, value) {
      if (this.settings[field] > 0) {
        this.settings[field]--;
        await this.saveSettings(field, this.settings[field]);
      }
    },
    async saveSettings(type, value) {
      await this.settingsStore.saveSettings2(type, value);
    },
    uploadLogo() {
      this.$refs.logoInput.click();
    },
    async handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        const base64 = await this.convertFileToBase64(file);
        const croppedBase64 = await this.cropImageToSize(base64, 136, 40);
        await this.saveSettings('SYSTEM_LOGO_BASE64', croppedBase64);
      }
    },
    cropImageToSize(base64, width, height) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = base64;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);
          resolve(canvas.toDataURL('image/png'));
        };
        img.onerror = error => reject(error);
      });
    },
    convertFileToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
  },
  async mounted() {
    await this.settingsStore.fetchSettings();
    this.settings = this.settingsStore.settings;
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
  /* 确保 padding 不会增加元素的总高度 */
}

.setting-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* 调整为居左排列 */
  background-color: #474747;
  height: 100vh;
  padding: 20px 20px 20px 0px;
  /* 内部 padding */
  font-size: 16px;
  line-height: 20px;
}

.setting-box {
  width: 830px;
  height: 268px;
  /* 固定高度 */
  background-color: white;
  border-radius: 12px;
  padding: 10px 20px;
  /* 上下padding 10px，左右padding 20px */
  box-sizing: border-box;
  position: relative;
}

.setting-header {
  display: flex;
  align-items: center;
}

.header-line {
  width: 2px;
  height: 12px;
  background-color: #057AB2;
  margin-right: 8px;
}

h1 {
  margin: 0;
  font-size: 16px;
  color: #5E6266;
  font-weight: 500;
}

hr {
  border: none;
  border-top: 1px solid #C2C3C4;
  width: 830px;
  margin-left: -20px;
  margin-right: 0px;
}

.setting-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px;
  /* 调整每行的间距以适应容器 */
  font-size: 16px;
}

.item-text {
  font-weight: 500;
}

.sub-text {
  color: #5E6266;
}

.control {
  display: flex;
  align-items: center;
}

.control span {
  width: 60px;
  text-align: center;
}

.control .control-button {
  width: 40px;
  height: 40px;
  background-color: #F0F3F5;
  font-size: 20px;
  /* 增大字体 */
  font-weight: bold;
  /* 加粗字体 */
}

.el-button {
  background-color: #F0F3F5;
  border: none;
}

.setting-button {
  width: 68px;
  height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  font-size: 16px;
}

.setting-arrow {
  margin-left: 8px;
  /* 设置箭头离文字8px */
}

.setting-item:last-child {
  margin-bottom: 0;
}
</style>