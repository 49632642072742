// utils/nameMapping.js
export const getChineseName = (name) => {
    const nameMap = {
      DeviceCenter: '设备中心',
      DataCenter: '数据中心',
      QualityCenter: '质控中心',
      Report: '患者报告',
      ReportRegister: '登记',
      ReportView: '报告',
      ReportSearch: '查询',
      ReportCrisis: '危机值',
      ReportSetting: '设置',
      FileSytem: '文档管理',
      File: '文档管理',
      MessageCenter: '消息中心',
      Settings: '系统设置',
      ReportSettings: '系统设置',
      UserSettings: '用户管理',
      StandardParamsSettings: '参数管理',
      RoleSettings: '角色权限',
      TemplateSettings: '打印模板',
      DepartmentSettings: '科室管理',
      DeviceSettings: '设备管理',
      DictSettings: '字典管理',
      invalid: '作废',
      restored: '恢复',
      saved: '保存',
      audited: '审核',
      cancelAudited: '取消审核',
      publish: '发布',
      recycle: '回收',
      print: '打印',
      deleted: '删除',
      addnode: '新增节点',
      deletenode: '删除节点',
      deletefile: '删除文件',
      invalidfile: '作废文件',
      uploadfile: '上传文件',
      
      downloadfile: '下载文件',
      editnode: '编辑节点',
    };
  
    return nameMap[name] || name; // 如果找到中文名称则返回，否则返回原始名称
  };
  