<template>
  <div class="registration-page">
    <div class="header">
      <div class="filter-bar">
        <div class="filter-item">
          <label for="hospital">医院</label>
          <el-select v-model="hospital" placeholder="选择医院" class="filter-input">
            <el-option v-for="hospital in hospitalList" :key="hospital.hospitalCode" :label="hospital.hospitalName"
              :value="hospital.hospitalCode">
            </el-option>
          </el-select>
        </div>

        <div class="filter-item">
          <label for="department">科室</label>
          <el-select v-model="department" placeholder="选择科室" class="filter-input">
            <el-option v-for="department in departmentList" :key="department.code" :label="department.name"
              :value="department.id">
            </el-option>
          </el-select>
        </div>

        <div class="filter-item">
          <el-date-picker v-model="dateStart" type="date" placeholder="申请日期" class="filter-date" />-
          <el-date-picker v-model="dateEnd" type="date" placeholder="止日期" class="filter-date" />
        </div>

        <div class="filter-item">
          <label for="status">筛选状态</label>
          <el-select v-model="status" placeholder="选择状态" class="filter-input">
            <el-option label="未生成" value="A"></el-option>
            <el-option label="已生成" value="B"></el-option>
            <el-option label="已绑定" value="C"></el-option>
          </el-select>
        </div>
      </div>
      <div class="button-group">
        <el-button type="primary" @click="search" class="ctrl-button">查询</el-button>
        <el-button @click="reset" class="ctrl-button">重置</el-button>
      </div>
    </div>
    <div class="content">
      <div class="left-pane">
        <el-scrollbar height="85vh">
          <el-table :data="patients" height="100%" width="100%" highlight-current-row @row-click="handleRowClick">
            <el-table-column prop="bedNumber" label="床号" width="auto" min-width="15%"></el-table-column>
            <el-table-column prop="patientNumber" label="住院号" width="auto" min-width="30%"></el-table-column>
            <el-table-column prop="name" label="姓名" show-overflow-tooltip width="auto"
              min-width="20%"></el-table-column>
            <el-table-column prop="gender" label="性别" width="auto" min-width="20%"></el-table-column>
            <el-table-column prop="age" label="年龄" width="auto" min-width="15%"></el-table-column>
          </el-table>
        </el-scrollbar>
        <div class="pagination">
          <el-pagination layout="prev, pager, next" :total="totalRecords" background :page-size="pageSize"
            :pager-count="5" :current-page="currentPage" @current-change="handlePageChange"
            @size-change="handleSizeChange">
          </el-pagination>
        </div>
      </div>
      <div class="right-pane">
        <div class="table-container" style="overflow: auto;">
          <el-table :data="sortedRecords" height="95%" width="100%" @selection-change="handleSelectionChange"
            @sort-change="handleOrderSortChange">
            <el-table-column type="selection" width="55" align="center"></el-table-column>
            <el-table-column prop="orderTime" label="申请日期" width="auto" min-width="20%" sortable="custom"
              :formatter="formatDate" :class-name="'nowrap'"></el-table-column>
            <el-table-column prop="visitNo" label="住院号" width="auto" min-width="20%" sortable="custom"
              :class-name="'nowrap'"></el-table-column>
            <el-table-column prop="patientName" label="姓名" width="auto" min-width="15%" sortable="custom"
              :class-name="'nowrap'"></el-table-column>
            <el-table-column prop="bedNo" label="床号" width="auto" min-width="10%" sortable="custom"
              :class-name="'nowrap'"></el-table-column>
            <el-table-column prop="genderCode" label="性别" width="auto" min-width="10%">
              <template #default="scope">
                {{ scope.row.genderCode === '1' ? '男' : '女' }}
              </template>
            </el-table-column>
            <el-table-column prop="itemName" label="医嘱名称" show-overflow-tooltip width="auto" min-width="25%"
              :class-name="'nowrap'"></el-table-column>
            <el-table-column prop="deliveryName" label="分组" width="auto" min-width="15%"
              :class-name="'nowrap'"></el-table-column>
            <el-table-column prop="barcode" label="条码号" width="auto" min-width="20%"
              :class-name="'nowrap'"></el-table-column>
          </el-table>
        </div>
        <div class="action-buttons">
          <el-button v-if="isAnyRowSelected && shouldShowGenerateBarcodeButton" type="primary" @click="generateBarcode"
            class="barcode-button">
            生成条码
          </el-button>
          <div v-else-if="isAnyRowSelected">
            <el-button type="danger" @click="revokeBarcode" class="barcode-button"> 撤销条码 </el-button>
            <el-button type="primary" @click="printBarcode" class="barcode-button"> 打印条码 </el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useAuthStore } from '@/stores/authStore'
import dayjs from 'dayjs'
import { ElMessage } from 'element-plus'

export default {
  data() {
    const today = new Date()
    const thirtyDaysAgo = new Date()
    thirtyDaysAgo.setDate(today.getDate() - 30)
    return {
      hospital: '',
      department: '',
      dateStart: thirtyDaysAgo,
      dateEnd: today,
      status: '',
      currentPage: 1,
      pageSize: 50,
      totalRecords: 1,
      selectedRecords: [],
      selectedRows: [],
      patients: [],
      hospitalList: [],
      departmentList: [],
      orderSortProp: '',
      orderSortOrder: ''
    }
  },
  computed: {
    sortedRecords() {
      if (!this.orderSortProp) return this.selectedRecords

      return [...this.selectedRecords].sort((a, b) => {
        const propA = a[this.orderSortProp]
        const propB = b[this.orderSortProp]

        if (this.orderSortOrder === 'ascending') {
          return propA > propB ? 1 : -1
        } else {
          return propA < propB ? 1 : -1
        }
      })
    },
    isAnyRowSelected() {
      return this.selectedRows.length > 0
    },
    shouldShowGenerateBarcodeButton() {
      return this.selectedRows.some((row) => !row.barcode)
    }
  },
  methods: {
    formatDate(row, column, cellValue) {
      if (!cellValue) return ''
      return dayjs(cellValue).format('YYYY-MM-DD HH:mm:ss')
    },
    async fetchHospitals() {
      try {
        const authStore = useAuthStore()
        const response = await authStore.hospitallist()
        if (response.code === 200) {
          this.hospitalList = response.data
          if (this.hospitalList.length > 0) {
            this.hospital = this.hospitalList[0].hospitalCode
            await this.fetchDepartments(this.hospital)
          }
        }
      } catch (error) {
        console.error('Error fetching hospitals:', error)
      }
    },
    async fetchDepartments(hospitalCode) {
      try {
        const authStore = useAuthStore()
        const response = await authStore.getDepartmentList(hospitalCode)
        if (response.code === 200) {
          this.departmentList = response.data
          if (this.departmentList.length > 0) {
            this.department = this.departmentList[0].id
          }
        }
      } catch (error) {
        console.error('Error fetching departments:', error)
      }
    },
    async getPatients(isSearch) {
      try {
        const authStore = useAuthStore()
        let param = {
          pageNumber: this.currentPage,
          pageSize: this.pageSize,
          deptID: this.department
        }
        param.startTime = Math.floor(new Date(this.dateStart).getTime() / 1000)
        param.endTime = Math.floor(new Date(this.dateEnd).getTime() / 1000)
        if (window.location.hostname === 'localhost' && window.location.port === '8080') {
          const fixedStartDate = new Date('2023-09-01').getTime()
          const fixedEndDate = new Date('2023-09-30T23:59:59.999').getTime()
          param.startTime = Math.floor(fixedStartDate / 1000)
          param.endTime = Math.floor(fixedEndDate / 1000)
        }
        const response = await authStore.getPatients(param)
        if (response.code === 200) {
          if (response.data.data.length > 0) {
            this.patients = response.data.data.map((patient) => ({
              bedNumber: patient.bedNo || '无',
              patientNumber: patient.visitNo,
              name: patient.patientName,
              gender: patient.genderCode === '1' ? '男' : '女',
              age: patient.age,
              visitType: patient.visitType
            }))
            this.totalRecords = response.data.total
            //this.selectedRows = [this.patients[0]]
            this.$nextTick(() => {
              this.handleRowClick(this.patients[0])
            })
          } else {
            ElMessage.warning('未查询到数据!')
          }
        } else {
          ElMessage.error('查询失败:' + response.msg)
        }
      } catch (error) {
        console.error('Error fetching patients:', error)
      }
    },
    async getOrders(params) {
      try {
        const authStore = useAuthStore()
        const response = await authStore.getOrders(params)
        if (response.code === 200) {
          this.selectedRecords = response.data.map((order) => ({
            orderTime: order.orderTime,
            visitNo: order.visitNo,
            patientName: order.patientName,
            bedNo: order.bedNo || '无',
            gender: order.genderCode === '1' ? '男' : '女',
            itemName: order.itemName,
            deliveryName: order.deliveryName || '无',
            orderLid: order.orderLid,
            barcode: order.barcode,
            applyNum: order.applyNum,
            visitType: order.visitType
          }))
        } else {
          console.error('Error fetching orders:', response.message)
        }
      } catch (error) {
        console.error('Error fetching orders:', error)
      }
    },
    async handleRowClick(row) {
      const params = {
        visitType: row.visitType,
        visitNo: row.patientNumber
      }
      await this.getOrders(params)
    },
    search() {
      const isSearch = true
      this.getPatients(isSearch)
    },
    reset() {
      const today = new Date()
      const thirtyDaysAgo = new Date()
      thirtyDaysAgo.setDate(today.getDate() - 30)
      this.hospital = ''
      this.department = ''
      this.dateStart = thirtyDaysAgo
      this.dateEnd = today
      this.status = ''
      this.currentPage = 1
      this.getPatients()
    },
    handlePageChange(page) {
      this.currentPage = page
      this.getPatients()
    },
    handleSizeChange(size) {
      this.pageSize = size
      this.getPatients()
    },
    handleSelectionChange(selection) {
      this.selectedRows = selection
    },
    async generateBarcode() {
      const authStore = useAuthStore()
      try {
        // 获取没有条码的行，并提取所有orderLid
        const rowsWithoutBarcode = this.selectedRows.filter((row) => !row.barcode)
        if (rowsWithoutBarcode.length === 0) return

        // 使用第一行的基本信息，所有orderLid用逗号连接
        const params = {
          visitType: rowsWithoutBarcode[0].visitType,
          visitNo: rowsWithoutBarcode[0].visitNo,
          applyNum: rowsWithoutBarcode[0].applyNum || '',
          orderPk: rowsWithoutBarcode.map(row => row.orderLid).join(',')
        }
        
        console.log('生成条码参数:', params)
        const result = await authStore.accessionGenerate(params)

        if (result.code === 200 && result.data.some((order) => order.barcode)) {
          ElMessage.success(`生成条码成功`)
          // 更新右侧表格
          result.data.forEach((order) => {
            const record = this.selectedRecords.find((r) => r.orderLid === order.orderLid)
            if (record) {
              record.barcode = order.barcode
            }
          })
        } else {
          ElMessage.error('生成条码失败')
        }
      } catch (error) {
        console.error('生成条码错误:', error.message)
        ElMessage.error('生成条码失败')
      }
    },
    async revokeBarcode() {
      const authStore = useAuthStore()
      try {
        const requests = this.selectedRows
          .filter((row) => row.barcode)
          .map((row) => {
            const params = {
              visitType: row.visitType,
              visitNo: row.visitNo,
              applyNum: row.applyNum || '',
              orderPk: row.orderLid
            }
            console.log('Revoking Barcode Params:', params)
            return authStore.accessionRemove(params)
          })

        const results = await Promise.all(requests)
        const successCount = results.filter(
          (result) => result.code === 200 && result.data.every((order) => !order.barcode)
        ).length

        if (successCount > 0) {
          ElMessage.success(`撤销条码成功 ${successCount} 条`)
          // 更新右侧表格
          results.forEach((result) => {
            if (result.code === 200) {
              result.data.forEach((order) => {
                const record = this.selectedRecords.find((r) => r.orderLid === order.orderLid)
                if (record) {
                  record.barcode = '' // 移除条码号
                }
              })
            }
          })
        } else {
          ElMessage.error('撤销条码失败')
        }
      } catch (error) {
        console.error('Error revoking barcode:', error.message)
      }
    },
    async printBarcode() {
      if (!this.selectedRows || this.selectedRows.length === 0) {
        ElMessage.warning('请选择需要打印的条码')
        return
      }

      try {
        const authStore = useAuthStore()
        // 使用 Set 来去重条码号
        const uniqueBarcodes = new Set(this.selectedRows.filter(row => row.barcode).map(row => row.barcode))
        
        const printPromises = Array.from(uniqueBarcodes).map(async barcode => {
          const params = { barcode }
          const response = await authStore.accessionPrint(params)
          console.log('response', response)

          if (response.size > 0) {
            const fileURL = window.URL.createObjectURL(new Blob([response], { type: 'application/pdf' }))
            
            // 创建一个隐藏的 iframe
            const printFrame = document.createElement('iframe')
            printFrame.style.display = 'none'
            document.body.appendChild(printFrame)
            
            printFrame.src = fileURL
            
            // 等待 iframe 加载完成后打印
            printFrame.onload = () => {
              try {
                printFrame.contentWindow.print()
                // 用户确认打印后再移除 iframe
                printFrame.contentWindow.onafterprint = () => {
                  document.body.removeChild(printFrame)
                  window.URL.revokeObjectURL(fileURL)
                }
              } catch (e) {
                console.error('打印过程出错:', e)
                ElMessage.error('打印过程出错，请重试')
              }
            }
          } else {
            ElMessage.error('打印失败,请联系管理员！')
          }
        })

        await Promise.all(printPromises)
      } catch (error) {
        console.error('打印条码失败:', error)
        ElMessage.error('打印失败,请联系管理员！')
      }
    },
    handleOrderSortChange({ prop, order }) {
      this.orderSortProp = prop
      this.orderSortOrder = order
    }
  },
  async mounted() {
    await this.fetchHospitals()
    await this.getPatients()
  }
}
</script>

<style scoped>
/* 样式保持不变 */
.registration-page {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 20px);
  width: calc(100% - 20px);
  background-color: #474747;
  overflow: hidden;
  margin-bottom: 20px;
  font-weight: 400;
}

:deep(.el-table__inner-wrapper:before) {
  height: 0;
}

.header {
  background-color: #474747;
  color: white;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
}

.filter-bar {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.filter-item {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  min-width: 200px;
  margin-bottom: 10px;
}

.filter-item label {
  margin-right: 5px;
}

.filter-input,
.filter-date {
  width: 150px;
}

.ctrl-button {
  width: 88px;
}

.registration-page .content {
  border-radius: 12px;
  display: flex;
  flex: 1;
  padding: 10px;
  overflow: hidden;
  background-color: #f5f6f7;
}

.left-pane {
  width: 382px;
  padding: 5px;
  background-color: white;
  margin-right: 12px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 12px;
}

.right-pane {
  flex: 1;
  background-color: white;
  overflow: hidden;
  border-radius: 12px;
  position: relative;
}

.table-container {
  padding: 5px;
  height: 100%;
  box-sizing: border-box;
}

.total-records {
  text-align: right;
  padding: 10px;
  font-size: 14px;
  color: #666;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 0;
  margin-top: auto;
}

.action-buttons {
  position: absolute;
  right: 15px;
  bottom: 8px;
  display: flex;
  gap: 10px;
}

.barcode-button {
  margin: 0;
}

.nowrap {
  white-space: nowrap;
}

/* 添加选中行的样式 */
.el-table .el-table__row.current-row {
  background-color: #f0f9eb; /* 淡绿色背景 */
}

</style>
