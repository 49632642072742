<template>
  <div class="registration-page">
    <div class="header">
      <div class="filter-bar">
        <!-- 过滤项 -->
        <div class="filter-item">
          <label for="dateStart">检验日期</label>
          <el-date-picker v-model="dateStart" type="date" placeholder="申请日期" class="filter-date" />-
          <el-date-picker v-model="dateEnd" type="date" placeholder="截止日期" class="filter-date" />
        </div>
        <div class="filter-item">
          <label for="hospital">医院</label>
          <el-select v-model="hospital" placeholder="选择医院" class="filter-input" @change="fetchDepartments">
            <el-option v-for="hospital in hospitalList" :key="hospital.hospitalCode" :label="hospital.hospitalName"
              :value="hospital.hospitalCode">
            </el-option>
          </el-select>
        </div>
        <div class="filter-item">
          <label for="department">科室</label>
          <el-select v-model="department" placeholder="选择科室" class="filter-input" @change="filterDevicesByDepartment">
            <el-option v-for="department in departmentList" :key="department.code" :label="department.name"
              :value="department.id">
            </el-option>
          </el-select>
        </div>
        <div class="filter-item">
          <label for="device">设备</label>
          <el-select v-model="device" placeholder="选择设备" class="filter-input">
            <el-option v-for="device in filteredDevices" :key="device.id" :label="device.name" :value="device.id">
            </el-option>
          </el-select>
        </div>
        <div class="filter-item">
          <label for="patient">患者</label>
          <el-input v-model="patient" placeholder="姓名关键字" class="filter-input"> </el-input>
        </div>
        <div class="filter-item">
          <label for="status">报告状态</label>
          <el-select v-model="status" placeholder="选择状态" class="filter-input">
            <el-option label="待绑定" value="await"></el-option>
            <el-option label="已保存" value="saved"></el-option>
            <el-option label="已审核" value="audited"></el-option>
            <el-option label="已发布" value="publish"></el-option>
            <el-option label="已作废" value="invalid"></el-option>
          </el-select>
        </div>
        <div class="filter-item">
          <el-checkbox label="已打印"></el-checkbox>
        </div>
      </div>
      <div class="button-group">
        <el-button type="primary" @click="search" class="ctrl-button">查询</el-button>
        <el-button @click="reset" class="ctrl-button">重置</el-button>
      </div>
    </div>

    <div class="content">
      <div class="report-title">报告信息</div>
      <div class="panels" ref="panels">
        <div class="left-pane" ref="leftPane">
          <el-table :data="samples" height="100%" width="100%" @row-click="handleRowClick" row-key="id"
            @selection-change="handleSelectionChange" ref="table">
            <el-table-column type="selection" width="30" align="center" :selectable="selectable"></el-table-column>
            <el-table-column prop="analyzeDate" label="检验时间" width="auto" min-width="160" :formatter="formatDateTime"
              show-overflow-tooltip></el-table-column>
            <el-table-column prop="sampleNum" label="样本号" width="auto" min-width="80"
              show-overflow-tooltip></el-table-column>
            <el-table-column prop="applyStatus" label="状态" width="auto" min-width="70" show-overflow-tooltip>
              <template v-slot:default="scope">
                <span :class="getStatusClass(scope.row.applyStatus)">{{ mapStatus(scope.row.applyStatus) }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="patientId" label="患者ID" width="auto" min-width="100"
              show-overflow-tooltip></el-table-column>
            <el-table-column prop="patient.name" label="患者姓名" width="auto" min-width="80"
              show-overflow-tooltip></el-table-column>
            <el-table-column prop="patient.sourceCode" label="患者来源" :formatter="formatPatientSource" width="auto"
              min-width="80" show-overflow-tooltip></el-table-column>
            <el-table-column prop="patient.inpatNum" label="住院号" width="auto" min-width="100"
              show-overflow-tooltip></el-table-column>
            <el-table-column prop="accessionNum" label="条码号" width="auto" min-width="110"
              show-overflow-tooltip></el-table-column>
            <el-table-column prop="patient.cardNum" label="卡号" width="auto" min-width="80"
              show-overflow-tooltip></el-table-column>
            <el-table-column prop="patient.bedNum" label="床号" width="auto" min-width="80"
              show-overflow-tooltip></el-table-column>
            <el-table-column prop="patient.sex" label="性别" :formatter="formatGender" width="auto" min-width="60"
              show-overflow-tooltip></el-table-column>
            <el-table-column prop="patient.birthdayDate" label="出生日期" :formatter="formatDate" show-overflow-tooltip
              width="auto" min-width="100"></el-table-column>
            <el-table-column prop="patient.diagnosis" label="诊断" width="auto" min-width="60"
              show-overflow-tooltip></el-table-column>
            <el-table-column prop="patient.sampleDesc" label="患者描述" width="auto" min-width="150"
              show-overflow-tooltip></el-table-column>
            <el-table-column prop="patient.address" label="联系地址" width="auto" min-width="150"
              show-overflow-tooltip></el-table-column>
            <el-table-column prop="patient.phone" label="联系方式" width="auto" min-width="150"
              show-overflow-tooltip></el-table-column>
          </el-table>
          <div class="pagination-container">
            <div class="pagination">
              <el-pagination layout="prev, pager, next" :total="totalRecords" background :page-size="pageSize"
                :current-page="currentPage" @current-change="handlePageChange"></el-pagination>
            </div>
            <div class="total-records">共 {{ totalRecords }} 条</div>
          </div>
        </div>
        <div class="divider" ref="divider"></div>
        <div class="right-pane" ref="rightPane">
          <div class="table-container">
            <el-table :data="selectedRecords" ref="rightTable" height="100%" width="100%"
              @selection-change="handleSelectionChange">
              <!-- 右侧表格列 -->
              <el-table-column type="index" label="序号" width="auto" min-width="60"></el-table-column>
              <el-table-column prop="code" label="参数" width="auto" min-width="100"
                show-overflow-tooltip></el-table-column>
              <el-table-column prop="name" label="项目名称" width="auto" min-width="140"
                show-overflow-tooltip></el-table-column>
              <el-table-column prop="value" label="结果" width="auto" min-width="100"></el-table-column>
              <el-table-column label="提示" width="auto" min-width="100">
                <template v-slot="{ row }">
                  <span v-if="
                    row.refRangeMin !== null &&
                    row.refRangeMax !== null &&
                    Number(row.value) < Number(row.refRangeMin)
                  " style="color: blue">↓</span>
                  <span v-else-if="
                    row.refRangeMin !== null &&
                    row.refRangeMax !== null &&
                    Number(row.value) > Number(row.refRangeMax)
                  " style="color: red">↑</span>
                </template>
              </el-table-column>
              <el-table-column prop="unit" label="单位" width="auto" min-width="80"></el-table-column>
              <el-table-column prop="referenceRange" label="参考范围" width="auto" min-width="120"><template
                  v-slot="{ row }">
                  <span v-if="row.refRangeMin !== null && row.refRangeMax !== null">
                    {{ row.refRangeMin }} - {{ row.refRangeMax }}
                  </span>
                  <span v-else> — </span>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div v-if="hasReportInfo" class="report-action">
            <div class="report-info">
              <div v-if="selectedSample.applyStatus" class="info-item">
                <span class="info-label"><img src="@/assets/feiji.png" width="13px" /></span><span
                  class="info-label">报告状态：</span><span :class="getStatusClass(selectedSample.applyStatus)">{{
                    mapStatus(selectedSample.applyStatus)
                  }}</span>
              </div>
              <div class="info-item">
                <span class="info-label"><img src="@/assets/dayin.png" width="13px" /></span><span
                  class="info-label">打印状态：</span>
                <span class="status-not-printed">{{ selectedSample.printCount === '' ? '未打印' : '已打印' }}</span>
              </div>
              <div v-if="selectedRecords[0]?.reviewer" class="info-item">
                <span class="info-label"><img src="@/assets/yinzhang.png" width="13px" /></span><span
                  class="info-label">审核人：</span>{{ selectedRecords[0]?.reviewer }}
              </div>
              <div v-if="selectedRecords[0]?.reviewTime" class="info-item">
                <span class="info-label"><img src="@/assets/shijian.png" width="13px" /></span><span
                  class="info-label">审核时间：</span>{{ selectedRecords[0]?.reviewTime }}
              </div>
            </div>
            <div class="action-buttons">
              <!-- <el-button type="danger" @click="revokeBarcode" class="barcode-button">
                回收
              </el-button>
              <el-button type="primary" @click="generateBarcode" class="barcode-button">
                打印
              </el-button> -->
              <template v-if="
                selectedSample.applyStatus === '' ||
                selectedSample.applyStatus === 'await' ||
                selectedSample.applyStatus === null">
                <el-button type="primary" @click="handleSave" class="ctrl-button small-font">保存</el-button>
                <el-button type="danger" @click="handleDelete" class="ctrl-button small-font">删除</el-button>
                <el-button type="info" @click="handleMerge" class="ctrl-button small-font">合并参数</el-button>
              </template>
              <template v-else-if="selectedSample.applyStatus === 'saved'">
                <el-button type="danger" @click="handleInvalidate" class="ctrl-button small-font">作废</el-button>
                <el-button type="success" @click="handleAudit" class="ctrl-button small-font">审核</el-button>
              </template>
              <template v-else-if="selectedSample.applyStatus === 'invalid'">
                <el-button type="success" @click="handleRestore" class="ctrl-button small-font">恢复</el-button>
                <el-button type="danger" @click="handleDelete" class="ctrl-button small-font">删除</el-button>
              </template>
              <template v-else-if="selectedSample.applyStatus === 'audited'">
                <el-button type="danger" @click="handleCancelAudit" class="ctrl-button small-font">取消审核</el-button>
                <el-button type="success" @click="handlePublish" class="ctrl-button small-font">发布</el-button>
              </template>
              <template v-else>
                <el-button type="primary" @click="handleRecycle" class="ctrl-button small-font">回收</el-button>
                <el-button type="success" @click="handlePrint" class="ctrl-button small-font">打印</el-button>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog v-model="dialogVisible" title="回收报告原因" width="360px" @close="handleDialogClose" class="recycle-dialog">
      <div class="recycle-reason-container">
        <label for="recycleReason">填写原因:</label>
        <el-input v-model="recycleReason" placeholder="请输入" class="recycle-input"></el-input>
      </div>
      <div slot="footer" class="dialog-footer" style="margin-top: 20px;flex-direction: row-reverse;">
        <el-button @click="dialogVisible = false" class="ctrl-button small-font">关闭</el-button>
        <el-button type="primary" @click="confirmRecycle" class="ctrl-button small-font">保存</el-button>
      </div>
    </el-dialog>
    <el-dialog v-model="mergeDialogVisible" title="合并参数" width="800px" @close="handleMergeDialogClose" class="merge-dialog" style="border-radius: 10px;">
      <div class="merge-list">
        <div class="target-sample">
          <label style="display: block;margin-bottom: 10px;">
            <img src="@/assets/Subtract1.png" width="12px" /> 目标样本
          </label>
          <el-table :data="mergeTargetSample" height="100px" width="100%" :header-cell-style="{ background: '#F5F6F7', color: '#5E6266' }">
            <el-table-column prop="deviceName" label="设备名称" width="auto" min-width="100"></el-table-column>
            <el-table-column prop="analyzeDate" label="检验时间" width="auto" min-width="160" :formatter="formatDateTime"></el-table-column>
            <el-table-column prop="sampleNum" label="样本号" width="auto" min-width="80"></el-table-column>
            <el-table-column prop="applyStatus" label="状态" width="auto" min-width="70" :formatter="formatStatus"></el-table-column>
          </el-table>
        </div>
        <div class="merge-list">
          <label style="margin-bottom: 10px;"><img src="@/assets/Subtract2.png" width="12px" /> 待合并样本列表</label>
          <div class="filter-item" style="margin-left: 10px;">
            <label for="mergeDateStart">检验日期:</label>
            <el-date-picker v-model="mergeDateStart" type="date" placeholder="选择开始时间" class="filter-date" />
          </div>
          <div class="filter-item" style="margin-left: 10px;">
            <label for="mergeDateEnd">至</label>
            <el-date-picker v-model="mergeDateEnd" type="date" placeholder="选择结束时间" class="filter-date" />
          </div>
          <div class="filter-item" style="margin-left: 10px;">
            <el-button type="primary" @click="fetchMergeList" class="ctrl-button">查询</el-button>
          </div>
          <el-table :data="mergeListData" height="400px" width="100%" :header-cell-style="{ background: '#F5F6F7', color: '#5E6266' }" @selection-change="handleMergeSelectionChange">
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column prop="deviceName" label="设备名称" width="auto" min-width="100"></el-table-column>
            <el-table-column prop="analyzeDate" label="检验时间" width="auto" min-width="160" :formatter="formatDateTime" sortable></el-table-column>
            <el-table-column prop="sampleNum" label="样本号" width="auto" min-width="80" sortable :sort-method="sortSampleNum"></el-table-column>
            <el-table-column prop="applyStatus" label="状态" width="auto" min-width="70" :formatter="formatStatus"></el-table-column>
          </el-table>
        </div>
      </div>
      <div slot="footer" class="dialog-footer" style="margin-top: 20px; display: flex; justify-content: flex-end;">
        <el-button @click="mergeDialogVisible = false" class="ctrl-button small-font">关闭</el-button>
        <el-button type="primary" :disabled="!selectedMergeRows.length" @click="confirmMerge" class="ctrl-button small-font">下一步</el-button>
      </div>
    </el-dialog>
    <el-dialog v-model="mergeParamsDialogVisible" title="合并参数" width="900px" @close="handleMergeParamsDialogClose" class="merge-params-dialog" style="border-radius: 10px;">
      <el-table :data="mergedParamsData" height="400px" width="100%" :header-cell-style="{ background: '#F5F6F7', color: '#5E6266' }">
        <el-table-column prop="code" label="参数代码" width="100" show-overflow-tooltip fixed="left"></el-table-column>
        <el-table-column prop="name" label="参数名称" width="auto" show-overflow-tooltip min-width="140"></el-table-column>
        <el-table-column prop="unit" label="单位" width="auto" min-width="80"></el-table-column>
        <el-table-column prop="referenceRange" label="参考范围" width="auto" min-width="120">
          <template v-slot="{ row }">
            <span v-if="row.refRangeMin !== null && row.refRangeMax !== null">
              {{ row.refRangeMin }} - {{ row.refRangeMax }}
            </span>
            <span v-else> — </span>
          </template>
        </el-table-column>
        <el-table-column prop="mergedValue1" label="合并结果" width="auto" min-width="160">
          <template v-slot:header="scope">
            <span v-html="'合并结果<br/>' + formatDateTime(mergeTargetSample[0], null, mergeTargetSample[0].analyzeDate, null)"></span>
          </template>
          <template v-slot="scope">
            <span>
              {{ scope.row.mergedValue1 }}
              <span v-if="scope.row.refRangeMin !== null && scope.row.refRangeMax !== null && Number(scope.row.mergedValue1) < Number(scope.row.refRangeMin)" style="color: blue">↓</span>
              <span v-else-if="scope.row.refRangeMin !== null && scope.row.refRangeMax !== null && Number(scope.row.mergedValue1) > Number(scope.row.refRangeMax)" style="color: red">↑</span>
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="mergedValue" label="目标样本" width="auto" min-width="160">
          <template v-slot:header="scope">
            <span v-html="'目标样本<br/>' + formatDateTime(mergeTargetSample[0], null, mergeTargetSample[0].analyzeDate, null)"></span>
          </template>
          <template v-slot="scope">
            <el-radio-group v-if="mergeStrategy === 'select'" v-model="scope.row.mergedValue1" @change="handleRadioChange(scope.row, scope.row.mergedValue)">
              <el-radio :label="scope.row.mergedValue">
                {{ scope.row.mergedValue || '-' }}
                <span v-if="
                  scope.row.mergedValue !== null && 
                  scope.row.mergedValue !== '' && 
                  scope.row.refRangeMin !== null && 
                  scope.row.refRangeMax !== null && 
                  Number(scope.row.mergedValue) < Number(scope.row.refRangeMin)
                " style="color: blue">↓</span>
                <span v-else-if="
                  scope.row.mergedValue !== null && 
                  scope.row.mergedValue !== '' && 
                  scope.row.refRangeMin !== null && 
                  scope.row.refRangeMax !== null && 
                  Number(scope.row.mergedValue) > Number(scope.row.refRangeMax)
                " style="color: red">↑</span>
              </el-radio>
            </el-radio-group>
            <template v-else>
              {{ scope.row.mergedValue || '-' }}
              <span v-if="
                scope.row.mergedValue !== null && 
                scope.row.mergedValue !== '' && 
                scope.row.refRangeMin !== null && 
                scope.row.refRangeMax !== null && 
                Number(scope.row.mergedValue) < Number(scope.row.refRangeMin)
              " style="color: blue">↓</span>
              <span v-else-if="
                scope.row.mergedValue !== null && 
                scope.row.mergedValue !== '' && 
                scope.row.refRangeMin !== null && 
                scope.row.refRangeMax !== null && 
                Number(scope.row.mergedValue) > Number(scope.row.refRangeMax)
              " style="color: red">↑</span>
            </template>
          </template>
        </el-table-column>
        <el-table-column v-for="MergeRow in selectedMergeRowsData" :key="MergeRow.sampleNum" width="auto" min-width="160">
          <template v-slot:header="scope">
            <span v-html="MergeRow.sampleNum + '<br/>' + formatDateTime(MergeRow, null, MergeRow.analyzeDate, null)"></span>
          </template>
          <template v-slot="scope">
            <el-radio-group v-if="mergeStrategy === 'select'" v-model="scope.row.mergedValue1" @change="handleRadioChange(scope.row, getSampleValue(scope.row, MergeRow))">
              <el-radio :label="getSampleValue(scope.row, MergeRow)">
                {{ getSampleValue(scope.row, MergeRow) }}
                <span v-if="
                  getSampleValue(scope.row, MergeRow) !== '-' && 
                  scope.row.refRangeMin !== null && 
                  scope.row.refRangeMax !== null && 
                  Number(getSampleValue(scope.row, MergeRow)) < Number(scope.row.refRangeMin)
                " style="color: blue">↓</span>
                <span v-else-if="
                  getSampleValue(scope.row, MergeRow) !== '-' && 
                  scope.row.refRangeMin !== null && 
                  scope.row.refRangeMax !== null && 
                  Number(getSampleValue(scope.row, MergeRow)) > Number(scope.row.refRangeMax)
                " style="color: red">↑</span>
              </el-radio>
            </el-radio-group>
            <template v-else>
              {{ getSampleValue(scope.row, MergeRow) }}
              <span v-if="
                getSampleValue(scope.row, MergeRow) !== '-' && 
                scope.row.refRangeMin !== null && 
                scope.row.refRangeMax !== null && 
                Number(getSampleValue(scope.row, MergeRow)) < Number(scope.row.refRangeMin)
              " style="color: blue">↓</span>
              <span v-else-if="
                getSampleValue(scope.row, MergeRow) !== '-' && 
                scope.row.refRangeMin !== null && 
                scope.row.refRangeMax !== null && 
                Number(getSampleValue(scope.row, MergeRow)) > Number(scope.row.refRangeMax)
              " style="color: red">↑</span>
            </template>
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer" style="margin-top: 20px; display: flex; justify-content: flex-end;">
        <div style="margin-right: 20px;">
          <el-radio-group v-model="mergeStrategy" @change="updateMergeResults">
            <el-radio value="earliest">保留最早结果</el-radio>
            <el-radio value="latest">保留最晚结果</el-radio>
            <el-radio value="select">逐个选择</el-radio>
          </el-radio-group>
        </div>
        <el-button @click="mergeParamsDialogVisible = false" class="ctrl-button small-font">关闭</el-button>
        <el-button type="primary" @click="finalizeMerge" class="ctrl-button small-font">完成合并</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { useAuthStore } from '@/stores/authStore'
import { ElLoading, ElMessage,ElMessageBox } from 'element-plus'
import dayjs from 'dayjs'
export default {
  components: {},
  data() {
    const today = new Date()
    const thirtyDaysAgo = new Date()
    thirtyDaysAgo.setDate(today.getDate() - 30)
    const fourteenDaysAgo = new Date()
    fourteenDaysAgo.setDate(today.getDate() - 14)
    return {
      patient: '',
      device: '',
      hospital: '',
      department: '',
      dateStart: thirtyDaysAgo,
      dateEnd: today,
      status: '',
      currentPage: 1,
      totalRecords: 0,
      pageSize: 21,
      selectedRecords: [],
      selectedSample: '',
      selectedRows: [],
      samples: [],
      allRecord: [],
      hospitalList: [],
      departmentList: [],
      deviceList: [], // 全部设备列表
      filteredDevices: [], // 滤后的设备列表
      patientList: [], // 存储患者列表
      patientLoading: false, // 控制患者列表加载状态
      dialogVisible: false, // 控制回收弹出框显示
      recycleReason: '', // 回收原因
      mergeDialogVisible: false, // 控制合并弹出框显示
      mergeTargetSample: [], // 用于存储合并目标样本
      mergeListData: [], // 用于存储待合并样本列表
      mergeDateStart: fourteenDaysAgo,
      mergeDateEnd: today,
      mergeParamsDialogVisible: false,
      mergedParamsData: [],
      disableNextButton: false,
      selectedMergeRows: [], // 用于存储勾选的合并样本
      sampleNums: [], // 用于存储样本号
      selectedMergeRowsData: [], // 用于存储所有选中的合并样本的数据
      mergeStrategy: 'earliest', // 默认选择保留最早结果
    }
  },
  computed: {
    hasReportInfo() {
      return (
        this.selectedRecords.length > 0 &&
        (this.selectedRecords[0].status ||
          this.selectedRecords[0].printStatus ||
          this.selectedRecords[0].reviewer ||
          this.selectedRecords[0].reviewTime)
      )
    }
  },
  methods: {
    formatPatientSource(row) {
      if (row.patient.sourceCode === 'outpatient') {
        return '门诊'
      } else if (row.patient.sourceCode === 'inpatient') {
        return '住院'
      } else {
        return row.patient.sourceCode // 如果来源代码不是 outpatient 或 inpatient，则返回原始代码
      }
    },
    formatGender(row) {
      if (row.patient.sex === 'man') {
        return '男'
      } else if (row.patient.sex === 'woman') {
        return '女'
      } else {
        return row.patient.sex // 如果性别代码不是 man 或 women，则返回原始代码
      }
    },
    getStatusClass(status) {
      const statusClasses = {
        null: 'status-orange',
        publish: 'status-green',
        await: 'status-orange',
        invalid: 'status-red',
        saved: 'status-blue',
        audited: 'status-orange'
      }
      return statusClasses[status] || ''
    },
    formatDate(row, column, value, index) {
      if (value == '' || value == null) return ''
      return dayjs(value).format('YYYY-MM-DD') // 格式化日期
    },
    formatDateTime(row, column, value, index) {
      // 返回年月日时分秒
      if (value == '' || value == null) return ''
      return dayjs(value).format('YYYY-MM-DD HH:mm:ss')
    },
    formatReferenceRange(row, column, cellValue, index) {
      return `${row.refRangeMin} - ${row.refRangeMax}`
    },

    async handlePageChange(page) {
      this.currentPage = page
      await this.search()
    },
    async fetchHospitals() {
      try {
        const authStore = useAuthStore()
        const response = await authStore.hospitallist()
        if (response.code === 200) {
          this.hospitalList = response.data
          if (this.hospitalList.length > 0) {
            this.hospital = this.hospitalList[0].hospitalCode
            await this.fetchDepartments(this.hospital)
          }
        }
      } catch (error) {
        console.error('Error fetching hospitals:', error)
      }
    },
    async fetchDepartments(hospitalCode) {
      try {
        const authStore = useAuthStore()
        const response = await authStore.getDepartmentList(hospitalCode)
        if (response.code === 200) {
          this.departmentList = response.data
          if (this.departmentList.length > 0) {
            this.department = this.departmentList[0].id // 自选择第一个科室
            await this.filterDevicesByDepartment() // 自动获取该科室下的设备
          }
        }
      } catch (error) {
        console.error('Error fetching departments:', error)
      }
    },
    async fetchDevices() {
      // 获取设备列表
      try {
        const authStore = useAuthStore()
        const response = await authStore.devicelist()
        if (response.code === 200) {
          this.deviceList = response.data
          await this.filterDevicesByDepartment() // 初次加载时过滤设备
        }
      } catch (error) {
        console.error('Error fetching devices:', error)
      }
    },
    filterDevicesByDepartment() {
      // 根据选中的科室过滤设备
      if (this.department) {
        this.filteredDevices = this.deviceList.filter((device) => device.deptId === this.department)
        if (this.filteredDevices.length > 0) {
          this.device = this.filteredDevices[0].id // 默认选择第一个设备
        }
      } else {
        this.filteredDevices = []
        this.device = '' // 如果没有科室选中，则清空设���选择
      }
    },
    reset() {
      const today = new Date()
      const thirtyDaysAgo = new Date()
      thirtyDaysAgo.setDate(today.getDate() - 30)
      this.hospital = ''
      this.department = ''
      this.dateStart = thirtyDaysAgo
      this.dateEnd = today
      this.status = ''
      this.device = ''
      console.log('重置条件')
      // 实现重置逻辑
    },
    async search() {
      const loading = ElLoading.service({ fullscreen: true });
      this.samples = []
      try {
        const authStore = useAuthStore()
        let params = {
          deviceId: this.device,
          startCheckTime: new Date(this.dateStart).getTime(),
          endCheckTime: new Date(this.dateEnd.setHours(23, 59, 59, 999)).getTime(),
          //startCheckTime: 1693497600,
          //endCheckTime: 1696089599,
          pageIndex: this.currentPage,
          pageSize: this.pageSize
        }

        // 判断如果是 localhost:8080，则使用固定的开始和结束时间,本地测试用
        if (window.location.hostname === 'localhost' && window.location.port === '8080') {
          const fixedStartDate = new Date('2023-09-01').getTime()
          const fixedEndDate = new Date('2023-09-30T23:59:59.999').getTime()
          params.startCheckTime = fixedStartDate
          params.endCheckTime = fixedEndDate
        }

        // 只有当状态被明确选择时才添加到参数中
        if (this.status) {
          params.applyStatus = this.status
        }
        if (this.patient) {
          params.patientName = this.patient
        }

        const response = await authStore.sampleList(params)
        if (response.code === 200) {
          if (response.data.total === 0) {
            this.selectedRecords = [] // 清空之前的选中记录
            ElMessage({
              message: '没有找到匹配的记录，请调整搜索条件。',
              type: 'warning'
            })
          } else {
            //检测样本
            this.samples = response.data.data.map((item) => ({
              id: item.id || '',
              sampleNum: item.sampleNum || '',
              accessionNum: item.accessionNum || '',
              deviceId: item.deviceId || '',
              deviceName: item.deviceName || '',
              requestDeptName: item.requestDeptName || '',
              patientId: item.patientId || '',
              creatorEmplId: item.creatorEmplId || '',
              creatorEmplName: item.creatorEmplName || '',
              payStatus: item.payStatus || '',
              auditedEmplId: item.auditedEmplId || '',
              auditedEmplName: item.auditedEmplName || '',
              operationEmplId: item.operationEmplId || '',
              operationEmplName: item.operationEmplName || '',
              printCount: item.printCount || '',
              applyStatus: item.applyStatus,
              analyzeDate: item.analyzeDate || '',
              auditedDate: item.auditedDate || '',
              updateDate: item.updateDate || '',
              createDate: item.createDate || '',
              deptId: item.deptId || '',
              listParams: item.listParams
                ? item.listParams.map((param) => ({
                  id: param.id || '',
                  code: param.code || '',
                  name: param.name || '',
                  unit: param.unit || '',
                  sort: param.sort || 0,
                  status: param.status || 0,
                  type: param.type || '',
                  calcType: param.calcType || '',
                  payType: param.payType || '',
                  refRangeMin: param.refRangeMin || '',
                  refRangeMax: param.refRangeMax || '',
                  criticalRangeMin: param.criticalRangeMin || '',
                  criticalRangeMax: param.criticalRangeMax || '',
                  refRange: param.refRange || '',
                  criticalRange: param.criticalRange || '',
                  value: param.value || '',
                  sampleId: param.sampleId || '',
                  createTime: param.createTime ? new Date(param.createTime).toLocaleString() : '',
                  deviceId: param.deviceId || '',
                  dotNum: param.dotNum || 0,
                  listSpecialParam: param.listSpecialParam || ''
                }))
                : [],
              patient: item.patient || '',
              isSaved: item.isSaved || ''
            }))
            this.totalRecords = response.data.total

            this.handleRowClick(this.samples[0])
          }
        } else {
          ElMessage.error(response.msg || '获取数据失败')
        }
      } catch (error) {
        console.error('Error fetching samples:', error)
        ElMessage.error('搜索请求失败')
      } finally {
        loading.close();
      }
    },
    async handleRowClick(row) {
      console.log('选中的行:', row)
      this.selectedSample = row
      this.selectedRecords = [] // 清空之前的选中记录
      try {
        const authStore = useAuthStore()
        const response = await authStore.getSample(row.id) // 使用行的 id 来获取详细信息
        if (response && response.code === 200) {
          this.selectedRecords = response.data // 假设详细信息在 data 字段中，设置到 selectedRecords
          console.log('selectedRecords', this.selectedRecords)
        } else {
          console.error('Failed to fetch sample details:', response.msg)
          this.$message.error('获取样本详细信息败: ' + response.msg)
        }
      } catch (error) {
        console.error('Error fetching sample details:', error.message)
        this.$message.error('获取样本详细信息异常: ' + error.message)
      }
    },
    generateBarcode() {
      console.log('Generating barcode...')
      // Logic to generate barcode
    },
    revokeBarcode() {
      console.log('Revoking barcode...')
      // Logic to revoke barcode
    },
    initResizableColumns() {
      const leftPane = this.$refs.leftPane
      const rightPane = this.$refs.rightPane
      const divider = this.$refs.divider

      if (!leftPane || !rightPane || !divider || !this.$refs.panels) {
        console.error('One or more elements are undefined, please check refs.')
        return
      }

      let isDragging = false

      const handleMouseMove = (e) => {
        if (!isDragging) return
        const dimensions = this.$refs.panels.getBoundingClientRect()
        const leftWidth = e.clientX - dimensions.left
        const rightWidth = dimensions.right - e.clientX
        if (leftWidth > 300 && rightWidth > 0) {
          leftPane.style.width = `${leftWidth}px`
          rightPane.style.width = `${rightWidth}px`
        }
      }

      const stopDrag = () => {
        isDragging = false
        document.removeEventListener('mousemove', handleMouseMove)
        document.removeEventListener('mouseup', stopDrag)
      }

      divider.addEventListener('mousedown', (e) => {
        isDragging = true
        document.addEventListener('mousemove', handleMouseMove)
        document.addEventListener('mouseup', stopDrag)
      })
    },
    formatStatus(row, column, value, index) {
      return this.mapStatus(value) // 调用 mapStatus 来转换状态码为中文
    },
    // 辅助方法：将状态码转换为中文
    mapStatus(status) {
      const statusMap = {
        await: '待绑定',
        saved: '已保存',
        audited: '已审核',
        publish: '已发布',
        invalid: '已作废'
      }
      // 考虑到 null 或未定义状态，均视为 '待绑定'
      return status ? statusMap[status] || '未知状' : '待绑定'
    },
    // 搜索患者，用户输入时触发
    async searchPatient(query) {
      if (query !== '') {
        this.patientLoading = true
        try {
          const authStore = useAuthStore()
          const params = { name: query } // 用户输入的关键词作为查询参数
          const response = await authStore.getPatient(params) // 调用 getPatient 方法
          if (response && response.code === 200) {
            this.patientList = response.data // 将获取到的患者数据设置到 patientList 中
          } else {
            ElMessage.error('获取者失败: ' + response.msg)
          }
        } catch (error) {
          console.error('Error fetching patients:', error.message)
          ElMessage.error('搜索患者失败: ' + error.message)
        } finally {
          this.patientLoading = false
        }
      } else {
        this.patientList = [] // 如果输入框为空，清空患者列表
      }
    },
    selectable(row, index) {
      // 如果 selectedRows 为空，允许选中
      if (this.selectedRows.length === 0) {
        return true;
      }
      // 如果 selectedRows 中存在不同的 applyStatus，不允许选中
      return this.selectedRows.every(selectedRow => selectedRow.applyStatus === row.applyStatus);
    },
    handleSelectionChange(selection) {
      console.log('selection', selection);
      this.selectedRows = selection;
      if (selection.length > 0) {
        this.handleRowClick(selection[selection.length - 1]);
      }
    },
    async handleDelete() {
      try {
        const authStore = useAuthStore()
        const samplesToDelete1 = this.selectedRows.length > 0 ? this.selectedRows : [this.selectedSample]
        const samplesToDelete = samplesToDelete1.map(row => ({
          id: row.id,
          applyStatus: row.applyStatus
        }))
        const response = await authStore.deleteSamples(samplesToDelete)
        if (response.code === 200) {
          ElMessage.success('删除成功')
          // 直接在界面上删除
          this.samples = this.samples.filter(sample => !samplesToDelete.some(deleted => deleted.id === sample.id))
          this.selectedRows = []
          this.selectedSample = ''
        } else {
          ElMessage.error(response.msg || '删除失败')
        }
      } catch (error) {
        console.error('Error deleting samples:', error)
        ElMessage.error('删除请求失败')
      }
    },
    handleRecycle() {
      if (this.selectedRows.length === 0 && !this.selectedSample) {
        ElMessage.warning('请至少选择一个样本进行回收。')
        return
      }
      this.dialogVisible = true // 显示回收弹出框
    },
    handleDialogClose() {
      this.dialogVisible = false
      this.recycleReason = ''
    },
    async confirmRecycle() {
      if (this.recycleReason == null || this.recycleReason == '') {
        ElMessage.warning('回收理由不能为空')
        return
      }

      const samplesToRecycle = this.selectedRows.length > 0 ? this.selectedRows : [this.selectedSample]

      const payload = {
        samples: samplesToRecycle.map(row => ({
          id: row.id,
          applyStatus: 'publish'
        })),
        targetStatus: 'saved'
      }

      try {
        const authStore = useAuthStore()
        // 先调用 /sample/Recycle
        const recycleResponse = await authStore.sampleRecycle({
          id: '',
          sampleid: samplesToRecycle.map(row => row.id).join(','),
          opercode: '',
          opername: '',
          reason: this.recycleReason,
          createtime: ''
        })

        if (recycleResponse && recycleResponse.code === 200) {
          // 再调用 sampleUpdate
          const updateResponse = await authStore.sampleUpdate(payload)
          if (updateResponse && updateResponse.code === 200) {
            ElMessage.success('样本已回收')
            samplesToRecycle.forEach(row => {
              row.applyStatus = 'saved' // 更新内存中的状态
              const index = this.samples.findIndex(sample => sample.id === row.id)
              if (index !== -1) {
                this.samples[index].applyStatus = 'saved'
              }
            })
            this.selectedRows = [] // 清空选中状态
            this.$refs.table.clearSelection() // 手动清空表格的选中状态
          } else {
            ElMessage.error(`回收失败: ${updateResponse.msg || '未知错误'}`)
          }
        } else {
          ElMessage.error(`回收失败: ${recycleResponse.msg || '未知错误'}`)
        }
      } catch (error) {
        console.error('回收过程中发生错误:', error)
        ElMessage.error('回收失败，请检查络或联系管理员')
      } finally {
        this.dialogVisible = false
        this.recycleReason = ''
      }
    },
    async handleInvalidate() {
      const selectedSamples = this.selectedRows.length > 0 ? this.selectedRows : [this.selectedSample]

      if (!selectedSamples || selectedSamples.length === 0) {
        ElMessage.warning('未选中任何样本，请选择一个或多个样本。')
        return
      }

      // 构建请求体
      const payload = {
        samples: selectedSamples.map(sample => ({
          id: sample.id,
          applyStatus: 'saved' // 确保这是在发起作废请求前的必要状态
        })),
        targetStatus: 'invalid'
      }

      try {
        const authStore = useAuthStore()
        const response = await authStore.sampleUpdate(payload)
        if (response.code === 200) {
          ElMessage.success('样本已成功作废')
          // 更新前端状态
          selectedSamples.forEach(sample => {
            sample.applyStatus = 'invalid'
            const index = this.samples.findIndex((s) => s.id === sample.id)
            if (index !== -1) {
              this.samples[index].applyStatus = 'invalid'
            }
          })
          // 你可能还需要重新加载或更新UI的其他部分，视具体情况而定
        } else {
          ElMessage.error(`作废失败: ${response.msg}`)
        }
      } catch (error) {
        console.error('作废过程中发生错误:', error)
        ElMessage.error('作废失败，请检查网络或联系管理员')
      }
    },
    async handleRestore() {
      const selectedSamples = this.selectedRows.length > 0 ? this.selectedRows : [this.selectedSample]

      if (!selectedSamples || selectedSamples.length === 0) {
        ElMessage.warning('未选中任何样本，请选择一个或多个样本。')
        return
      }

      // 构建请求体
      const payload = {
        samples: selectedSamples.map(sample => ({
          id: sample.id,
          applyStatus: 'invalid' // 确保这是在发起恢复请求前的必要状态
        })),
        targetStatus: 'await'
      }

      try {
        const authStore = useAuthStore()
        const response = await authStore.sampleUpdate(payload)
        if (response.code === 200) {
          ElMessage.success('样本已成功恢复')
          // 更新前端状态
          selectedSamples.forEach(sample => {
            sample.applyStatus = 'await'
            const index = this.samples.findIndex((s) => s.id === sample.id)
            if (index !== -1) {
              this.samples[index].applyStatus = 'await'
            }
          })
          // 你可能还需要重新加载或更新UI的其他部分，视具体情况而定
        } else {
          ElMessage.error(`恢复失败: ${response.msg}`)
        }
      } catch (error) {
        console.error('恢复过程中发生错误:', error)
        ElMessage.error('恢复失败，请检查网络或联系管理员')
      }
    },
    async handleSave() {
      const selectedSamples = this.selectedRows.length > 0 ? this.selectedRows : [this.selectedSample]

      if (!selectedSamples || selectedSamples.length === 0) {
        ElMessage.warning('未选中何样本，请选择一个或多个样本。')
        return
      }

      // 构建请求体
      const payload = {
        samples: selectedSamples.map(sample => ({
          id: sample.id,
          applyStatus: 'await' // 确保这是在发起保存请求前的必要状态
        })),
        targetStatus: 'saved'
      }

      try {
        const authStore = useAuthStore()
        const response = await authStore.sampleUpdate(payload)
        if (response.code === 200) {
          ElMessage.success('样本已成功保存')
          // 更新前端状态
          selectedSamples.forEach(sample => {
            sample.applyStatus = 'saved'
            const index = this.samples.findIndex((s) => s.id === sample.id)
            if (index !== -1) {
              this.samples[index].applyStatus = 'saved'
            }
          })
          // 你可能需要重新加载或更新UI的其他部分，视具体情况而定
        } else {
          ElMessage.error(`保存失败: ${response.msg}`)
        }
      } catch (error) {
        console.error('保存过程中发生错误:', error)
        ElMessage.error('保存失败，请检查网络或联系管理员')
      }
    },
    async handlePublish() {
      const selectedSamples = this.selectedRows.length > 0 ? this.selectedRows : [this.selectedSample]
      if (!selectedSamples || selectedSamples.length === 0) {
        ElMessage.warning('未选中任何样本，请选择一个或多个样本。')
        return
      }
      const payload = {
        samples: selectedSamples.map(sample => ({
          id: sample.id,
          applyStatus: 'audited'
        })),
        targetStatus: 'publish'
      }
      try {
        const authStore = useAuthStore()
        const response = await authStore.sampleUpdate(payload)
        if (response.code === 200) {
          selectedSamples.forEach(sample => {
            sample.applyStatus = 'publish'
            const index = this.samples.findIndex((s) => s.id === sample.id)
            if (index !== -1) {
              this.samples[index].applyStatus = 'publish'
            }
          })
          ElMessage.success('样本已成功发布')
        } else {
          ElMessage.error(`发布失败: ${response.msg}`)
        } 
      } catch (error) {
        console.error('发布过程中发生错误:', error)
        ElMessage.error('发布失败，请检查网络或联系管理员')
      }
    },
    async handleAudit() {
      const selectedSamples = this.selectedRows.length > 0 ? this.selectedRows : [this.selectedSample]

      if (!selectedSamples || selectedSamples.length === 0) {
        ElMessage.warning('未选中任何样本，请选择一个或多个样本。')
        return
      }

      // 构建请求体
      const payload = {
        samples: selectedSamples.map(sample => ({
          id: sample.id,
          applyStatus: 'saved' // 确这是在发审核请求前的必要状态
        })),
        targetStatus: 'audited'
      }

      try {
        const authStore = useAuthStore()
        const response = await authStore.sampleUpdate(payload)
        if (response.code === 200) {
          console.log(response.data[0].applyStatus);
          if (response.data[0].applyStatus === 'publish') {
            selectedSamples.forEach(sample => {
              sample.applyStatus = 'publish'
              const index = this.samples.findIndex((s) => s.id === sample.id)
              if (index !== -1) {
                this.samples[index].applyStatus = 'publish'
              }
            })
            ElMessage.success('样本已成功审核')
          } else {
            // 更新前端状态
            selectedSamples.forEach(sample => {
              sample.applyStatus = 'audited'
              const index = this.samples.findIndex((s) => s.id === sample.id)
              if (index !== -1) {
                this.samples[index].applyStatus = 'audited'
              }
            })
            ElMessage.warning('样本已成功审核')
          }

          // 你可能还需要重新加载或更新UI的其他部分，视具体情况而定
        } else {
          ElMessage.error(`审核失败: ${response.msg}`)
        }
      } catch (error) {
        console.error('审核过程中发生错误:', error)
        ElMessage.error('审核失败，请检查网络或联系管理员')
      }
    },

    // 取消审核
    async handleCancelAudit() {
      const selectedSamples = this.selectedRows.length > 0 ? this.selectedRows : [this.selectedSample]

      if (!selectedSamples || selectedSamples.length === 0) {
        ElMessage.warning('未选中任何样本，请选择一个或多个样本。')
        return
      }

      // 构建请求体
      const payload = {
        samples: selectedSamples.map(sample => ({
          id: sample.id,
          applyStatus: 'audited' // 确保这是在发起取消审核请求前的必要状态
        })),
        targetStatus: 'saved'
      }

      try {
        const authStore = useAuthStore()
        const response = await authStore.sampleUpdate(payload)
        if (response.code === 200) {
          ElMessage.success('样本已成功取消审核')
          // 更新前端状态
          selectedSamples.forEach(sample => {
            sample.applyStatus = 'saved'
            const index = this.samples.findIndex((s) => s.id === sample.id)
            if (index !== -1) {
              this.samples[index].applyStatus = 'saved'
            }
          })
          // 你可能需要重新加载或更新UI的其他部分，视具体情况而定
        } else {
          ElMessage.error(`取消审核失败: ${response.msg}`)
        }
      } catch (error) {
        console.error('取消审核过程中发生错误:', error)
        ElMessage.error('取消审核失败，请检查网络或联系管理员')
      }
    },
    handleMerge() {
      if (this.selectedRows.length > 0) {
        // 如果勾选了多个样本，只带最后一个样本的数据过去
        this.mergeTargetSample = [this.selectedRows[this.selectedRows.length - 1]]
      } else if (this.selectedSample) {
        // 如果没有勾选，使用当前中的样本
        this.mergeTargetSample = [this.selectedSample]
      } else {
        ElMessage.warning('请少选择一个样本进合并。')
        return
      }
      this.mergeListData = [];
      this.selectedMergeRows = [];
      const initialData = this.$options.data();
      this.mergeDateStart = new Date(initialData.mergeDateStart); // 恢复初始设置的值
      this.mergeDateEnd = new Date(initialData.mergeDateEnd); // 恢复初始设置的值
      this.mergeDialogVisible = true // 显示合并弹出框
    },
    handleMergeDialogClose() {
      this.mergeDialogVisible = false
      //this.mergeTargetSample = [] // 清空数据
    },
    confirmMerge() {
      this.mergeParamsDialogVisible = true
      const paramsMap = new Map()

      // 首先添加目标样本的参数
      this.selectedRecords.forEach(record => {
        if (!paramsMap.has(record.code)) {
          paramsMap.set(record.code, {
            id: record.id,
            code: record.code,
            name: record.name,
            unit: record.unit,
            refRangeMin: record.refRangeMin,
            refRangeMax: record.refRangeMax,
            mergedValue: record.value,
            targetSampleNum: this.mergeTargetSample[0].sampleNum,
            selectedSampleValues: {},
            // 添加一个标记表示这是目标样本的参数
            isTargetParam: true
          })
        }
        paramsMap.get(record.code).selectedSampleValues[record.sampleId] = record.value
      })

      // 添加待合并样本的参数
      this.selectedMergeRows.forEach(sample => {
        sample.listParams.forEach(param => {
          if (!paramsMap.has(param.code)) {
            // 如果是新的参数（目标样本没有的），添加到Map中
            paramsMap.set(param.code, {
              id: param.id,
              code: param.code,
              name: param.name,
              unit: param.unit,
              refRangeMin: param.refRangeMin,
              refRangeMax: param.refRangeMax,
              mergedValue: null, // 目标样本没有该参数时为null
              mergedValue1: param.value, // 初始合并值使用待合并样本的值
              targetSampleNum: this.mergeTargetSample[0].sampleNum,
              selectedSampleValues: {},
              isTargetParam: false // 标记这不是目标样本的参数
            })
          }
          // 无论参数是否存在于目标样本，都添加待合并样本的值
          paramsMap.get(param.code).selectedSampleValues[sample.id] = param.value
        })
      })

      this.mergedParamsData = Array.from(paramsMap.values())
      this.mergeStrategy = 'earliest'
      this.updateMergeResults()
    },
    handleMergeParamsDialogClose() {
      this.mergeParamsDialogVisible = false
      this.mergedParamsData = []
    },
    updateMergeResults() {
      this.mergedParamsData.forEach(param => {
        const valuesWithDates = [
          { value: param.mergedValue, date: dayjs(this.mergeTargetSample[0].analyzeDate) },
          ...this.selectedMergeRows.flatMap(row => 
            row.listParams
              .filter(p => p.code === param.code) // 根据 code 进行过滤
              .map(p => ({ value: p.value, date: dayjs(row.analyzeDate) }))
          )
        ].filter(({ date }) => date.isValid());
        if (valuesWithDates.length === 0) {
          // Handle the case where there are no valid dates
          param.mergedValue1 = null; // or some default value
          return;
        }

        if (this.mergeStrategy === 'earliest') {
          const earliest = valuesWithDates.reduce((prev, curr) => {
            //console.log(`Comparing ${prev.date} with ${curr.date}`);
            return prev.date.isBefore(curr.date) ? prev : curr;
          });
          console.log(`Earliest date: ${earliest.date}`);
          param.mergedValue1 = earliest.value;
        } else if (this.mergeStrategy === 'latest') {
          const latest = valuesWithDates.reduce((prev, curr) => {
            //console.log(`Comparing ${prev.date} with ${curr.date}`);
            return prev.date.isAfter(curr.date) ? prev : curr;
          });
          //console.log(`Latest date: ${latest.date}`);
          param.mergedValue1 = latest.value;
        } else if (this.mergeStrategy === 'select') {
          // 逐个选择的逻辑可以在这里添加

        }
      });
    },
    finalizeMerge() {
      ElMessageBox.confirm('您确定要合并这些参数吗？', '确认合并', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        // 构建合并数据
        const mergeData = {
          ...this.mergeTargetSample[0],
          listParams: this.mergedParamsData.map(param => {
            return {
              id: param.id,
              code: param.code,
              value: param.mergedValue1
            }
          })
        };

        const payload = {
          target: mergeData, // 将 mergeData 作为 target 字段的值
          mergedIds: [] // 添加新字段 mergedIds，初始化为空数组
        };

        // 调试信息
        console.log('合并数据:', mergeData);

        try {
          const authStore = useAuthStore(); // 获取 authStore 实例
          // 调用合并接口
          await authStore.mergeSamples(payload);
          this.mergeDialogVisible = false;
          this.mergeParamsDialogVisible = false;
          ElMessage.success('合并成功');
          
          // 重新调用 search 方法刷新报告信息
          await this.search();
        } catch (error) {
          console.error('合并失败:', error);
          ElMessage.error('合并失败，请重试');
        } finally {
          this.mergeParamsDialogVisible = false;
        }
      }).catch(() => {
        // 用户取消合并
      });
    },
    async fetchMergeList() {
      const loading = ElLoading.service({
        lock: true,
        text: '加载中...',
        background: 'rgba(0, 0, 0, 0.3)',
        target: '.merge-dialog' // 指定加载动画显示的目标元素
      });
      console.log("fetchMergeList");
      try {
        const authStore = useAuthStore()
        let params = {
          deviceId: this.device,
          startCheckTime: Math.floor(new Date(this.mergeDateStart).getTime() / 1000),
          endCheckTime: Math.floor(new Date(this.mergeDateEnd).getTime() / 1000)
        }

        // 判断如果是 localhost:8080，则用固定的开始和结束时间,本地测试用
        if (window.location.hostname === 'localhost' && window.location.port === '8080') {
          const fixedStartDate = new Date('2023-09-01').getTime()
          const fixedEndDate = new Date('2023-09-30T23:59:59.999').getTime()
          params.startCheckTime = Math.floor(fixedStartDate / 1000)
          params.endCheckTime = Math.floor(fixedEndDate / 1000)
        }
        const response = await authStore.getMergedList(params)
        if (response.code === 200) {
          // 过滤出 applyStatus 为 null 或空符串的数据
          this.mergeListData = response.data.filter(item => 
            // 状态条件
            (item.applyStatus === null || item.applyStatus === '' || item.applyStatus === 'await') 
            // 排除目标样本
            && item.sampleNum !== this.mergeTargetSample[0].sampleNum
          )
          if (this.mergeListData.length === 0) {
            ElMessage.warning('没有待合并样本');
          }
          console.log("mergeListData", this.mergeListData);
        } else {
          ElMessage.error(response.msg || '获取待合并样本列表失败')
        }
      } catch (error) {
        console.error('Error fetching merge list:', error)
        ElMessage.error('获取待合并样本列表请求失败', error)
      } finally {
        loading.close();
      }
    },
    handleMergeSelectionChange(selection) {
      this.selectedMergeRows = selection;
      this.updateSampleNums();
      this.updateMergedParamsData();
    },
    updateSampleNums() {
      this.sampleNums = this.selectedMergeRows.map(row => row.sampleNum);
      // 获取所有选中的合并样本的所有数据
      this.selectedMergeRowsData = this.selectedMergeRows;
      console.log("selectedMergeRowsData", this.selectedMergeRowsData);
    },
    updateMergedParamsData() {
      const mergedParamsData = [];
      this.selectedMergeRows.forEach(row => {
        row.listParams.forEach(param => {
          const existingParam = mergedParamsData.find(p => p.code === param.code);
          if (existingParam) {
            existingParam.selectedSampleValues[row.sampleNum] = param.value;
          } else {
            mergedParamsData.push({
              code: param.code,
              name: param.name,
              unit: param.unit,
              referenceRange: `${param.refRangeMin} - ${param.refRangeMax}`,
              mergedValue: param.value,
              selectedSampleValues: { [row.sampleNum]: param.value },
            });
          }
        });
      });
      this.mergedParamsData = mergedParamsData;
    },
    sortSampleNum(a, b) {
      const numA = parseInt(a.sampleNum, 10);
      const numB = parseInt(b.sampleNum, 10);
      return numA - numB;
    },
    getSampleValue(row, MergeRow) {
      // 查找匹配的参数,只需要比对 code 即可
      const sample = MergeRow.listParams.find(s => s.code === row.code);
      if (sample) {
        return sample.value;
      }
      return '-';
    },
    updateMergedValue(row, value) {
      row.mergedValue1 = value;
    },
    // 处理radio选择变化
    handleRadioChange(row, value) {
      row.mergedValue1 = value; // 更新合并结果值
    }
  },
  async mounted() {
    await this.fetchHospitals()
    await this.fetchDevices() // 获取设备列表
    this.initResizableColumns()
  }
}
</script>
<style scoped>
.registration-page {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 20px);
  width: calc(100% - 20px);
  background-color: #474747;
  overflow: hidden;
  margin-bottom: 20px;
  font-weight: 400;
}

.header {
  background-color: #474747;
  color: white;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
}

.filter-bar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}

.filter-item {
  display: inline-flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 5px;
}

:deep(.filter-item .el-checkbox__label) {
  color: white;
  font-size: 16px;
}

.filter-input,
.filter-date {
  flex: 1;
  min-width: 150px;
}

.button-group {
  margin-left: auto;
  display: flex;
  gap: 10px;
}

.ctrl-button {
  width: 88px;
}

.registration-page .content {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 10px;
  border-radius: 12px;
  background-color: #f0f3f5;
  overflow: hidden;
}

.report-title {
  width: 100%;
  padding: 10px;
  color: #057ab2;
  font-weight: 500;
}

.main-content {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.panels {
  display: flex;
  margin: 0px;
  height: 94%;
  padding: 0px;
}

.left-pane {
  padding: 5px;
  background-color: white;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 50%;
  border-radius: 12px 0px 0px 12px;
}

.divider {
  background-color: #ccc;
  cursor: ew-resize;
  width: 5px;
  /* 分隔线的宽度 */
}

.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  gap: 10px;
}

:deep(.left-pane .el-table .el-table__cell) {
  padding: 3px 0;
}

:deep(.right-pane .el-table .el-table__cell) {
  padding: 3px 0;
}

.right-pane {
  flex: 1;
  display: flex;
  background-color: white;
  overflow: hidden;
  border-radius: 0px 12px 12px 0px;
  position: relative;
  flex-direction: column;
  width: 10px;
  height: 100%;
}

.table-container {
  padding: 5px;
  height: calc(100% - 70px);
  box-sizing: border-box;
}

.report-action {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 0;
}

.report-info {
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  gap: 10px;
  font-size: 14px;
  color: #666;
}

.info-item {
  display: flex;
  align-items: center;
}

.info-label {
  margin-right: 5px;
}

.status-published {
  color: green;
}

.status-not-printed {
  color: orange;
}

.action-buttons {
  display: flex;
  margin-right: 20px;
}

.barcode-button {
  width: 88px;
  margin: 0;
}

.status-green {
  color: green;
}

.status-orange {
  color: orange;
}

.status-red {
  color: red;
}

.status-blue {
  color: blue;
}

/**重要--隐藏表头的全选框,否则状态有问题 */
:deep(.el-table__header-wrapper .el-checkbox) {
  display: none;
}

.recycle-dialog {
  .recycle-reason-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }

  .recycle-input {}

  .dialog-footer {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  }
}

.merge-dialog {
  color: #333;
}
.merge-list {
  margin-top: 20px;
}
.el-table::before {
  height: 0; /* 去掉表格下方的横线 */
}
</style>
